



































































































































































import CommonFilterSettings from "@/includes/logic/Modules/models/filters/CommonFilterSettings";
import { InputSetups } from "@/mixins/input-setups";
import PunishActionSetting from "../../punish-actions/components/PunishActionSetting.vue";
import {
  FloodCommonSettings
} from "@/includes/logic/Modules/models/modules/filters/FloodFilterHandlerModule/FloodFilterHandlerModule";
import Placeholders from "@/mixins/placeholders/placeholders";
import FilterResetPunishSettings from "@/components/Modules/components/filters/FilterResetPunishSettings.vue";
import { CustomPunishBuilders } from "@/includes/logic/Modules/models/filters/types";

import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator';

@Component({
  data() {
    return {
      StepUnit,
      FloodCommonSettings
    }
  },
  components: {
    FilterResetPunishSettings,
    PunishActionSetting,
    NestedContent,
    NumberInput,
    SwitchInput,
    TimeUnitInput
  }
})
export default class FilterCommonSettings extends Mixins(InputSetups, Placeholders) {
  @VModel() settings!: CommonFilterSettings

  @Prop({ type: String, default: '' }) fieldsPrefix!: string

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Prop({ type: Object, default: null }) customPunishBuilders!: CustomPunishBuilders

  get isFloodCommonSettings() {
    return this.settings instanceof FloodCommonSettings
  }

  get prefix() {
    if (this.fieldsPrefix) {
      return `${ this.fieldsPrefix }_`
    }

    return ''
  }
}
