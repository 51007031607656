var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-users-attack-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.pageHelpMessageOrDescription(_vm.module.model)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),(_vm.hasAtLeastOneTag)?_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}}):_vm._e()]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":!!_vm.getTagsByFieldKey('auto_attack_mode') || _vm.getTagsByFieldKey('ban_inout_users_time') || _vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'auto_attack_mode_count',
          'tariffTags': _vm.getTagsByFieldKey('auto_attack_mode'),
          'min': 0,
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: 20,
            hasFieldButton: true,
            disabledValue: 0
          },
          'disabled': _vm.disabled
        }
      }}}),(_vm.autoAttackModeInitValue !== null)?_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'auto_attack_mode_interval',
          'tariffTags': _vm.getTagsByFieldKey('auto_attack_mode'),
          'hasAnchor': true,
          'units': _vm.lowerThenMinOrHigherThenHour ? undefined : [ _vm.StepUnit.Minute, _vm.StepUnit.Hour ],
          'max': _vm.lowerThenMinOrHigherThenHour ? Number.MAX_SAFE_INTEGER : 3600,
          'settingWrapper': {
            customiseValue: _vm.StepUnit.Hour,
            hasFieldButton: true,
            disabledValue: 0
          },
          'disabled': _vm.disabled
        }
      }}}):_vm._e(),_c('number-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'ban_inout_users',
          'tariffTags': _vm.getTagsByFieldKey('ban_inout_users'),
          'hasAnchor': true,
          'min': 0,
          'disabled': _vm.disabled
        }
      }}}),(_vm.module.model.params.ban_inout_users > 0)?_c('nested-content',{staticClass:"mb-0"},[_c('time-unit-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'ban_inout_users_time',
            'tariffTags': _vm.getTagsByFieldKey('ban_inout_users_time'),
            'hasAnchor': true,
            'min': _vm.StepUnit.Minute,
            'disabled': _vm.disabled,
            'settingWrapper': {
              customiseValue: _vm.StepUnit.Hour * 2,
              hasFieldButton: true,
              disabledValue: 0
            },
          }
        }}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }