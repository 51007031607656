






import TriggerValidationMixin from '@/components/TriggerSetup/components/TriggerAdvancedValidate/TriggerValidationMixin'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component
export default class TriggerAdvancedValidateMixin extends Mixins<TriggerValidationMixin>(TriggerValidationMixin) {
  @VModel() model !:any

  get invalidTriggerField():string | null {
    return this.firstInvalidTriggerField(this.validTrigger)
  }

  get validTrigger(): Record<string, boolean> {
    return {
      trigger_validate_actions: this.trigger_validate_actions,
      trigger_validate_actions_values: this.trigger_validate_actions_values,
    }
  }

  get trigger_validate_actions():boolean {
    if (this.model.length > 0) {
      return this.hasActionGroups(this.model)
    }

    return true
  }

  get trigger_validate_actions_values():boolean {
    if (this.model.length > 0) {
      return this.actionValuesValid(this.model, this.checkAction)
    }

    return true
  }
}
