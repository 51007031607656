

























import PunishMessage from "@/components/Modules/punish-actions/components/PunishMessage.vue";
import { InputSetups } from "@/mixins/input-setups";
import { DeleteMessageAction } from "@/includes/logic/Modules/models/PunishActions";
import { ClassProperties } from "@/includes/utils/types";

import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';

@Component({
  data() {
    return {
      StepUnit
    }
  },
  components: {
    PunishMessage,
    TimeUnitInput
  }
})
export default class DeleteMessageActionView extends Mixins(InputSetups) {
  @VModel() model!: DeleteMessageAction

  @Prop() defaultModel!: Partial<ClassProperties<DeleteMessageAction>>
}
