







































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import AudioFilterHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/filters/AudioFilterHandlerModule/AudioFilterHandlerModuleBuilder";

import ModuleConfigSettings from '../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '../../components/ModuleConfigSettingsWrapper.vue'
import FilterJournal from "../../components/filters/FilterJournal.vue";
import FilterCommonSettings from "../../components/filters/FilterCommonSettings.vue";
import FilterNewUsersSettings from "../../components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "../../components/filters/FilterRemoveEditedSetting.vue";
import FilterExtraSettings from "../../components/filters/FilterExtraSettings.vue";
import FilterResetPunishSettings from '../../components/filters/FilterResetPunishSettings.vue'
import FilterSettingLayout from "../../components/filters/layout/FilterSettingLayout.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    FilterSettingLayout,
    FilterExtraSettings,
    FilterRemoveEditedSetting,
    FilterResetPunishSettings,
    FilterNewUsersSettings,
    FilterCommonSettings,
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
  },
})
export default class AudioFilterHandlerModuleView extends Mixins(InputSetups, ModuleBindings) {
  @VModel() module!: AudioFilterHandlerModuleBuilder
}
