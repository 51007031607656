



























































































































































import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import MetricCard from '@/components/CommunityDashboard/components/MetricCard.vue'
import FiltersFiringStat from '@/components/CommunityDashboard/MetricComponents/MetricFilters/FiltersFiringStat.vue'
import EntityCardWithStatistics from '@/components/CommunityDashboard/MetricComponents/EntityCardWithStatistics.vue'
import { filtersStatistics } from '@/components/CommunityDashboard/logic/metrics/filtersStatistics'
import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";
import { PunishActionTypesEnum } from "@/includes/logic/Modules/models/PunishActions";
import ModulesRoutesHelperMixin from "@/includes/logic/Modules/mixins/ModulesRoutesHelperMixin";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { tooltipTrigger } from "piramis-base-components/src/shared/utils/tooltipTrigger";

import { Component, Mixins } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'

type FilterParams = {
  color: string
  title: string
  icon: string
}

@Component({
  methods: { tooltipTrigger },
  components: {
    EntityCardWithStatistics,
    FiltersFiringStat,
    MetricCard,
    VueApexCharts,
    EmptyData,
    Icon,
    Accordion
  },
})
export default class MetricFilters extends Mixins(ModulesRoutesHelperMixin) {
  isOpen = false

  filtersStat = filtersStatistics()

  get timeTicks() {
    return this.filtersStat.getTimeTicks()
  }

  get chartOptions(): ApexOptions {
    return {
      chart: {
        animations: {
          enabled: false
        },
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        }
      },
      tooltip: {
        y: {
          formatter(val: number, opts?: any): string {
            return val.toFixed(0)
          }
        },
      },
      grid: {
        show: false
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      legend: {
        show: false
      },
      xaxis: {
        labels: {
          show: false
        },
        categories: this.filtersStat.getTimeTicks().timeTicks.map(t => moment(t).format('D MMM')),
      },
      yaxis: {
        show: false,
      },
    }
  }

  get firingChartOptions(): ApexOptions {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true
        }
      },
      colors: [ 'rgba(var(--a-primary), 1)' ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: this.filtersStat.getTimeTicks().timeTicks.map(t => moment(t).format('D MMM')),
      },
    }
  }

  getFilterParams(filterModuleType: ModuleTypesEnum) {
    let arr: Array<FilterParams> = []

    if (ModuleManagerState) {
      const filterToUse = ModuleManagerState.modules.find(module => module instanceof FilterModule && module.type === filterModuleType) as FilterModule | undefined

      if (filterToUse) {
        let params = {
          ban_type: filterToUse.filter.ban_punish_action.type === PunishActionTypesEnum.Disabled,
          first_message_ban_type: filterToUse.filter.first_message_punish_action.type === PunishActionTypesEnum.Disabled,
          remove: filterToUse.filter.warning_punish_action.type === PunishActionTypesEnum.DeleteMessage,
        }

        if (params.remove) {
          arr.push({
            color: 'text-danger',
            title: this.$t('filter_tooltip_delete_title').toString(),
            icon: 'icon-delete'
          })
        }

        if (params.first_message_ban_type) {
          arr.push({
            color: 'text-success',
            title: this.$t('filter_tooltip_newbie_allowed_title').toString(),
            icon: 'icon-check-circle'
          })
        }

        if (!params.ban_type) {
          arr.push({
            color: 'text-warning',
            title: this.$t('filter_tooltip_punish_title').toString(),
            icon: 'icon-cloud-lightning'
          })
        }
      }

    }

    return arr
  }
}
