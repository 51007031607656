











import { StatisticsPeriodKey } from '@/views/chat/statistics/logic/types'
import { ChatStatistics } from '@/includes/Api/Statistics.api'

import { UseFields } from "piramis-base-components/src/components/Pi";
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'

import { Component, Prop } from "vue-property-decorator";
import VueApexCharts from 'vue-apexcharts'
import { merge } from 'lodash'
import { ApexOptions } from 'apexcharts'
import moment from "moment";

@Component({
  components: {
    TableExportButtons,
    VueApexCharts
  },
  data() {
    return {
      StatisticsPeriodKey
    }
  }
})
export default class UsersActivityBars extends UseFields {
  @Prop() rawData!: ChatStatistics

  @Prop() stat!:StatisticsPeriodKey

  activityUsers: { days: any, hours: any } | null = null

  options(options: Partial<ApexOptions>): ApexOptions {
    const base = {
      chart: {
        type: 'bar',
        animations: {
          enabled: false
        },
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,
        padding:{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0
        }
      },
      xaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        marker: {
          show: false
        },
        y: {
          formatter: (val: number): string => val.toFixed(0).toString(),
          title: {
            formatter: (seriesName: string): string => ''
          }
        }
      }
    }

    return merge(base, options)
  }

  mounted(): void {
    const days: Record<string, number> = {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
    }

    let hours = fromToTicks(
      moment().startOf('d').format(),
      moment().endOf('d').format(),
      { format: 'HH:mm', amount: 1, unit: 'h' }
    ).mapTimeData;

    this.rawData.message_count_by_day.forEach(item => {
      days[moment(item.date).format('d')] += item.users_count
    })

    this.rawData.message_count_by_hour.forEach(item => {
      if (hours[moment(item.time).format('HH:mm')] !== undefined) {
        hours[moment(item.time).format('HH:mm')] += item.users_count
      } else {
        hours[moment(item.time).format('HH:mm')] = 0
      }
    })

    this.activityUsers = {
      days: {
        series: [
          {
            name: this.$t('statistics_msg_count_users_day_activity_title').toString(),
            data: [ ...Object.values(days) ]
          }
        ],
        labels: this.options(
          {
            fill: {
              colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-primary), 1)', 'rgba(var(--a-primary), 1)', 'rgba(var(--a-primary), 1)', 'rgba(var(--a-primary), 1)', 'rgba(var(--a-danger), 1)', 'rgba(var(--a-danger), 1)' ]
            },
            xaxis: { categories: Object.keys(days).map(item => this.$t(`week_day_picker_${ item.toLowerCase() }`).toString()) },
            plotOptions: {
              bar:{
                distributed: true,
              }
            }
          }
        )
      },
      hours: {
        series: [
          {
            name: this.$t('statistics_msg_count_users_hour_activity_title').toString(),
            data: [ ...Object.entries(hours).sort((a, b) => Number(a[0]) - Number(b[0])).map(item => item[1]) ]
          }
        ],
        labels: this.options({ xaxis: { categories: Object.keys(hours) } } )
      }
    }
  }
}
