





































































































































































































import ModuleConfigSettings from "@/components/Modules/components/ModuleConfigSettings.vue";
import GoBackButton from "@/components/Modules/components/PageTitleButtons/GoBackButton.vue";
import ModuleConfigSettingsWrapper from "@/components/Modules/components/ModuleConfigSettingsWrapper.vue";
import SaveModuleButton from "@/components/Modules/components/PageTitleButtons/SaveModuleButton.vue";
import { InputSetups } from "@/mixins/input-setups";
import Placeholders from "@/mixins/placeholders/placeholders";
import ModuleBindings from "@/includes/logic/Modules/mixins/ModuleBindings";
import VotebanHandlerModuleBuilder from "@/includes/logic/Modules/models/modules/VotebanHandlerModule/VotebanHandlerModuleBuilder";
import PunishActionSetting from "@/components/Modules/punish-actions/components/PunishActionSetting.vue";

import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import {
  MediaEditorMode
} from "piramis-base-components/src/components/NewMessageEditors/types";
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import MultiMessageEditorWithMediaInput from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  data() {
    return {
      MediaEditorMode
    }
  },
  components: {
    PunishActionSetting,
    SaveModuleButton,
    ModuleConfigSettingsWrapper,
    GoBackButton,
    ModuleConfigSettings,
    TimeUnitInput,
    TextInput,
    NumberInput,
    MultiMessageEditorWithMediaInput,
    SwitchInput,
    NestedContent
  }
})
export default class VotebanHandlerModuleView extends Mixins(InputSetups, Placeholders, ModuleBindings) {
  @VModel() module!: VotebanHandlerModuleBuilder

  get votebanMessage() {
    return this.module.model.params.message
  }

  set votebanMessage(value: VotebanHandlerModuleBuilder['model']['params']['message']) {
    this.module.model.params.message = value
  }

  created() {
    this.module.toggleProtection = !!this.module.model.params.protection
  }
}
