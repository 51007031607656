var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-command-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.descriptionKey) ? _vm.$t(_vm.module.model.descriptionKey) : null,"help-message":_vm.$te(_vm.module.model.pageHelpMessageKey) ? _vm.$t(_vm.module.model.pageHelpMessageKey) : null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('module-enable-switch',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model),callback:function ($$v) {_vm.$set(_vm.module, "model", $$v)},expression:"module.model"}}),(_vm.module.model.config.enabled)?[_c('a-divider'),_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'report_remove',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}}),(_vm.module.model.params.report_remove)?_c('nested-content',[_c('number-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'report_count_to_delete_message',
              'min': 0,
              settingWrapper: {
                disabledValue: 0,
                hasFieldButton: true,
                customiseValue: 1
              },
              'disabled': _vm.disabled
            }
          }}})],1):_vm._e(),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'report_to_admins',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}}),(!_vm.module.model.params.report_to_admins && !Object.keys(_vm.module.model.params.report_users).length)?_c('a-alert',{attrs:{"show-icon":"","type":"warning"}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('report_to_admins_and_report_command_to_all_disabled_alert_message'))},slot:"message"})]):_vm._e(),_c('config-field',{staticClass:"my-3 py-1",attrs:{"has-anchor":"","title":_vm.$t('field_report_admins_title'),"help-message":_vm.$t('field_report_admins_help_message'),"field-key":"report_users"}},[_c('add-admin',{attrs:{"add-type":_vm.AddAdminType.Report,"module-guid":_vm.module.model.guid,"add-users-disabled":_vm.ModuleManagerState && !_vm.ModuleManagerState.isCurrentModuleSaved,"disabled":_vm.disabled,"users-path":{
            model: _vm.module.model.params,
            key: 'report_users'
          }}})],1),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'report_command_to_all',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0 mt-1",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'report_info_message',
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'placeholders': _vm.BASE_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramBase,
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0 mt-1",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'report_action_message',
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'placeholders': _vm.BASE_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramBase,
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}})]:_vm._e()],2),(_vm.module.model.config.enabled)?_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'journal_enabled',
          'hasAnchor': true,
          'prefix': ((_vm.module.model.snakeCaseType) + "_"),
          'disabled': _vm.disabled
        }
      }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }