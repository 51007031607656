var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"xp-handler-module-view"},[_c('page-title',{attrs:{"tags":_vm.tariffTag,"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.$te(_vm.module.model.descriptionKey) ? _vm.$t(_vm.module.model.descriptionKey) : null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),(!_vm.tariffTag)?_c('save-module-button',{on:{"save-module":_vm.saveModule}}):_vm._e()]},proxy:true}])}),(!_vm.getTagsByFieldKey("extended_reputation"))?[_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': this,
            'key': 'xp_action_jack_pot_chance_computed',
            'max': 100,
            'step': 0.1,
            'hasAnchor': true,
            'settingWrapper': {
              customiseValue: 0.9,
              hasFieldButton: true,
              disabledValue: 0
            },
            'disabled': _vm.disabled
          }
        }}}),(_vm.xp_action_jack_pot_chance_computed > 0)?_c('nested-content',[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'xp_action_jack_pot_multiply',
              'min': 1,
              'max': 100,
              'hasAnchor': true,
              'disabled': _vm.disabled
            }
          }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'xp_action_jack_pot_message',
              'placeholders': _vm.XP_PLACEHOLDERS,
              'targetToUpload': _vm.groupUploadTarget,
              'editorMode': _vm.MediaEditorMode.TelegramShort,
              'base-api-url': 'api.chatkeeper.info/cabinet',
              'hasAnchor': true,
              'disabled': _vm.disabled
            },
          }}})],1):_vm._e(),_c('config-field',{staticClass:"mt-5 mb-2",attrs:{"title":_vm.$t('xp_action_reward_title'),"mini-help-message":_vm.$t('xp_action_reward_mini_help_message')}}),_c('nested-content',[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'xp_min_message_len',
              'hasAnchor': true,
              'disabled': _vm.disabled
            }
          }}}),_c('config-field',{staticClass:"mt-5 mb-2",attrs:{"title":_vm.$t('reward_write_message_title'),"mini-help-message":_vm.$t('reward_write_message_mini_help_message'),"has-anchor":true,"field-key":"reward_write_message"}},[_c('nested-content',[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  // model: this,
                  // key: 'rewardWriteMessageMin',
                  'model': _vm.module.model.params.xp_action_reward.RewardWriteMessage,
                  'key': 'min',
                  'prefix': 'reward_write_message_',
                  'settingWrapper': {
                    customiseValue: 1,
                    hasFieldButton: true,
                    disabledValue: 0
                  },
                  'disabled': _vm.disabled
                }
              }}}),_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  // model: this,
                  // key: 'rewardWriteMessageMax',
                  'model': _vm.module.model.params.xp_action_reward.RewardWriteMessage,
                  'key': 'max',
                  'prefix': 'reward_write_message_',
                  'settingWrapper': {
                    customiseValue: 5,
                    hasFieldButton: true,
                    disabledValue: 0
                  },
                  'disabled': _vm.disabled
                }
              }}})],1)],1),_c('config-field',{staticClass:"mt-5 mb-2",attrs:{"title":_vm.$t('reward_write_reply_message_title'),"mini-help-message":_vm.$t('reward_write_reply_message_mini_help_message'),"has-anchor":true,"field-key":"reward_write_reply"}},[_c('nested-content',[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params.xp_action_reward.RewardWriteReplyMessage,
                  'key': 'min',
                  'prefix': 'reward_write_reply_message_',
                  'settingWrapper': {
                    customiseValue: 5,
                    hasFieldButton: true,
                    disabledValue: 0
                  },
                  'disabled': _vm.disabled
                }
              }}}),_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params.xp_action_reward.RewardWriteReplyMessage,
                  'key': 'max',
                  'prefix': 'reward_write_reply_message_',
                  'settingWrapper': {
                    customiseValue: 5,
                    hasFieldButton: true,
                    disabledValue: 0
                  },
                  'disabled': _vm.disabled
                }
              }}})],1)],1),_c('config-field',{staticClass:"mt-5 mb-2",attrs:{"title":_vm.$t('reward_write_first_message_title'),"mini-help-message":_vm.$t('reward_write_first_message_mini_help_message'),"has-anchor":true,"field-key":"reward_write_first_message"}},[_c('nested-content',[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params.xp_action_reward.RewardWriteFirstMessage,
                  'key': 'min',
                  'prefix': 'reward_write_first_message_',
                  'settingWrapper': {
                    customiseValue: 5,
                    hasFieldButton: true,
                    disabledValue: 0
                  },
                  'disabled': _vm.disabled
                }
              }}}),_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params.xp_action_reward.RewardWriteFirstMessage,
                  'key': 'max',
                  'prefix': 'reward_write_first_message_',
                  'settingWrapper': {
                    customiseValue: 5,
                    hasFieldButton: true,
                    disabledValue: 0
                  },
                  'disabled': _vm.disabled
                }
              }}})],1)],1),_c('config-field',{staticClass:"mt-5 mb-2",attrs:{"title":_vm.$t('reward_start_dialog_title'),"mini-help-message":_vm.$t('reward_start_dialog_mini_help_message'),"has-anchor":true,"field-key":"reward_start_dialog"}},[_c('nested-content',[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params.xp_action_reward.RewardStartDialog,
                  'key': 'min',
                  'prefix': 'reward_start_dialog_',
                  'settingWrapper': {
                    customiseValue: 5,
                    hasFieldButton: true,
                    disabledValue: 0
                  },
                  'disabled': _vm.disabled
                }
              }}}),_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params.xp_action_reward.RewardStartDialog,
                  'key': 'max',
                  'prefix': 'reward_start_dialog_',
                  'settingWrapper': {
                    customiseValue: 5,
                    hasFieldButton: true,
                    disabledValue: 0
                  },
                  'disabled': _vm.disabled
                }
              }}})],1)],1),_c('config-field',{staticClass:"mt-5 mb-2",attrs:{"title":_vm.$t('reward_mention_title'),"mini-help-message":_vm.$t('reward_mention_mini_help_message'),"has-anchor":true,"field-key":"reward_mention"}},[_c('nested-content',[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params.xp_action_reward.RewardMention,
                  'key': 'min',
                  'prefix': 'reward_mention_',
                  'settingWrapper': {
                    customiseValue: 5,
                    hasFieldButton: true,
                    disabledValue: 0
                  },
                  'disabled': _vm.disabled
                }
              }}}),_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.module.model.params.xp_action_reward.RewardMention,
                  'key': 'max',
                  'prefix': 'reward_mention_',
                  'settingWrapper': {
                    customiseValue: 5,
                    hasFieldButton: true,
                    disabledValue: 0
                  },
                  'disabled': _vm.disabled
                }
              }}})],1)],1)],1),_c('config-field',{staticClass:"mt-5 mb-2",attrs:{"title":_vm.$t('xp_bonus_title'),"mini-help-message":_vm.$t('xp_bonus_mini_help_message')}}),_c('nested-content',[_c('time-unit-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'xp_bonus_interval',
              'time-units': [ _vm.StepUnit.Second, _vm.StepUnit.Minute, _vm.StepUnit.Hour, _vm.StepUnit.Day, _vm.StepUnit.Week ],
              'hasAnchor': true,
              'settingWrapper': {
                customiseValue: _vm.StepUnit.Minute * 5,
                hasFieldButton: true,
                disabledValue: 0
              },
              'disabled': _vm.disabled
            }
          }}}),_c('number-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'xp_bonus_message_count',
              'hasAnchor': true,
              'settingWrapper': {
                customiseValue: 100,
                hasFieldButton: true,
                disabledValue: 0
              },
              'disabled': _vm.disabled
            }
          }}}),_c('number-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'xp_bonus_multiply',
              'hasAnchor': true,
              'settingWrapper': {
                customiseValue: 3,
                hasFieldButton: true,
                disabledValue: 0
              },
              'disabled': _vm.disabled
            }
          }}}),_c('time-unit-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'xp_bonus_time',
              'time-units': [ _vm.StepUnit.Second, _vm.StepUnit.Minute, _vm.StepUnit.Hour, _vm.StepUnit.Day, _vm.StepUnit.Week ],
              'hasAnchor': true,
              'disabled': _vm.disabled
            }
          }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'start_bonus_xp_message',
              'placeholders': _vm.XP_PLACEHOLDERS,
              'targetToUpload': _vm.groupUploadTarget,
              'editorMode': _vm.MediaEditorMode.TelegramShort,
              'base-api-url': 'api.chatkeeper.info/cabinet',
              'hasAnchor': true,
              'disabled': _vm.disabled
            },
          }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'end_bonus_xp_message',
              'placeholders': _vm.XP_PLACEHOLDERS,
              'targetToUpload': _vm.groupUploadTarget,
              'editorMode': _vm.MediaEditorMode.TelegramShort,
              'base-api-url': 'api.chatkeeper.info/cabinet',
              'hasAnchor': true,
              'disabled': _vm.disabled
            },
          }}})],1)],1)]:_c('not-available-options-overlay',{staticClass:"relative",attrs:{"video":_vm.$i18n.locale !== 'ru' ?
      'https://client.chatkeeper.info/assets/video/video_ChatKeeperBot_xp_en.m4v' :
      'https://client.chatkeeper.info/assets/video/video_ChatKeeperBot_xp_ru.m4v',"description":_vm.$t("option_description_extended_reputation"),"tags":_vm.tariffTag}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }