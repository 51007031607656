var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-extra-settings"},[(_vm.ignoreCaption || _vm.settings.comments_mode !== _vm.CommentsModeEnum.None)?_c('a-card',{staticClass:"mt-5"},[_c('select-input',{directives:[{name:"hide-once",rawName:"v-hide-once",value:(_vm.settings.comments_mode === _vm.CommentsModeEnum.None),expression:"settings.comments_mode === CommentsModeEnum.None"}],staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'comments_mode',
          'options': _vm.commentsModeOptions,
          'clearable': false,
          'tariffTags': _vm.tag,
          'hasAnchor': true,
          'disabled': _vm.disabled,
        }
      }}}),(_vm.ignoreCaption)?_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'ignore_caption',
          'hasAnchor': true,
          'disabled': _vm.disabled,
        }
      }}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }