import { BasePunishActionBuilder } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import { MuteMediaAction, PunishActionTypesEnum } from "../..";

import { plainToInstance } from "class-transformer";

export class MuteMediaActionBuilder extends BasePunishActionBuilder<MuteMediaAction> {
  type = PunishActionTypesEnum.MuteMedia

  icon = 'media_bluetooth_off'

  view = () => import('@/components/Modules/punish-actions/MuteMediaActionView.vue');

  getModel(): MuteMediaAction {
    return plainToInstance(MuteMediaAction, {
      type: PunishActionTypesEnum.MuteMedia,
      delete_message: true,
      delete_message_delay: 0,
      time: 600,
      message: []
    })
  }
}

export const MuteMediaActionBuilderInstance= new MuteMediaActionBuilder()
