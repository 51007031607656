import { BasePunishActionBuilder } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import { BanAction, PunishActionTypesEnum } from "../..";

import { plainToInstance } from "class-transformer";

export class BanActionBuilder extends BasePunishActionBuilder<BanAction> {
  type = PunishActionTypesEnum.Ban

  icon = 'person_add_disabled'

  view = () => import('@/components/Modules/punish-actions/BanActionView.vue');

  getModel(): BanAction {
    return plainToInstance(BanAction, {
      type: PunishActionTypesEnum.Ban,
      delete_message: true,
      delete_message_delay: 0,
      time: 600,
      message: []
    })
  }
}

export const BanActionBuilderInstance= new BanActionBuilder()
