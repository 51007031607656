import { errorNotification } from "@/includes/NotificationService";

(async () => {
  if (!('ResizeObserver' in window)) {
    try {
      const module = await import('@juggle/resize-observer')

      window.ResizeObserver = module.ResizeObserver
    } catch (e) {
      errorNotification(e)
    }
  }
})()
