



































































import LeaveUsersHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/Greeting/LeaveUsersHandlerModule/LeaveUsersHandlerModuleBuilder";
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { InputSetups } from '@/mixins/input-setups'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'
import Placeholders from "@/mixins/placeholders/placeholders";

import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    MultiMessageEditorWithMediaInput,
    SelectInput,
    SwitchInput,
    CenteredColumnLayout,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
  },
  data() {
    return {
      MediaEditorMode
    }
  }
})
export default class LeaveUsersHandlerModuleView extends Mixins(ModuleBindings, TariffsTagsHelper, InputSetups, Placeholders) {
  @VModel() module!: LeaveUsersHandlerModuleBuilder

  get tariffTags() {
    if (this.$store.getters.isChatSet) {
      return this.getTagsByFieldKey('after_greeting_triggers')
    }

    return null
  }

  // get triggersOptions(): Array<SelectOption> {
  //   if (this.isChatManagerVm) {
  //     return this.
  //   }
  // }
}
