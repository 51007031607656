











































































































































































































import BotIsNotAdminMixin from '@/mixins/BotIsNotAdminMixin'
import ChatLicense from '../../includes/logic/ChatLicense'
import themeConfig from '@/config'
import { errorNotification } from '@/includes/NotificationService'
import { BaseChatInfo } from '@/includes/types/Chat/types'
import ChatApi from '@/includes/Api/Chat.api'
import { PreparedChatInfoStat } from "@/includes/types/ChatsInfoStat.types";

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'

import VueApexCharts from 'vue-apexcharts'
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'
import { capitalize } from "lodash";

@Component({
  methods: {
    capitalize
  },
  components: {
    VueApexCharts,
    EmptyData,
    Icon
  }
})
export default class ChatCard extends Mixins<BotIsNotAdminMixin>(BotIsNotAdminMixin) {
  @Prop({ type: Object, required: true }) chat!: BaseChatInfo

  @Prop({ type: Object, required: false }) statistics!: PreparedChatInfoStat | undefined

  @Prop({ type: Boolean, required: true }) statisticsLoaded!: boolean

  @Emit('updateChatInfo')
  updateChatInfo(diagnostic: BaseChatInfo['diagnostic']) {
    return {
      id: this.chat.chat_id,
      diagnostic
    }
  }

  requestInProcess = false

  themeConfig = themeConfig

  chatLicense = new ChatLicense(this.chat)

  get chatIdParam() {
    return { [EntityTypes.CHAT_ID]: this.chat.chat_id.toString() }
  }

  buyLink = {
    name: 'buy',
    params: {
      ...this.chatIdParam
    },
  }

  statisticsLink = {
    name: 'statistics',
    params: {
      ...this.chatIdParam
    },
  }

  chartOptions: ApexOptions = {
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2.5,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0,
        stops: [ 0, 100 ],
      },
    },
    xaxis: {
      categories: fromToTicks(moment().add(-7, 'd').format(), moment().format(), { format: 'LL' }).timeTicks,
      axisBorder: {
        show: false,
      },
      labels: { show: false },
    },
    yaxis: [ {
      show: false,
      labels: {
        formatter(val: number, opts?: any): string | string[] {
          return val.toFixed()
        }
      }
    } ],
    tooltip: {
      x: {
        show: true,
      },
    },
  }

  get chatButtonTitle() {
    return this.$t('button_go_to_chat')
  }

  get chatRoute() {
    return {
      name: 'Community_dashboard',
      params: {
        ...this.chatIdParam
      },
    }
  }

  get chatCardTitleIcon(): string {
    if (this.chat.type === 'forum') {
      return 'forum'
    }

    return 'chat'
  }

  refreshAdmins() {
    this.requestInProcess = true

    ChatApi.runChatAction({
      chat_id: this.chat.chat_id,
      action: 'addchatadmins'
    })
      .then(({ data }) => {
        if (!!data && !!data.chat) {
          this.updateChatInfo(data.chat.diagnostic)
        }
      })
      .catch(errorNotification)
      .finally(() => {
        this.requestInProcess = false
      })
  }

  getLicenseAvatar(licenseType: string) {
    try {
      return themeConfig.getTariff(licenseType).img
    } catch (e) {
      console.error(e)
      return ''
    }
  }

  created() {
  }
}

