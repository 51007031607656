import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import { BasePunishActionModel, PunishActionSubTypes } from "../../PunishActions";

import { BlackWhiteListItem } from "piramis-base-components/src/components/BlackWhiteList/types";
import { RawChipItem } from "piramis-base-components/src/components/TypedChips/typedChips.types";

import { Type } from "class-transformer";

export default class FacecontrolHandlerModule extends BaseModule {
  type: ModuleTypesEnum = ModuleTypesEnum.FacecontrolHandlerModule;

  @Type(() => FacecontrolHandlerModuleParams)
  params!: FacecontrolHandlerModuleParams;
}

class FacecontrolHandlerModuleParams {
  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  ban_action!: BasePunishActionModel

  @Type(() => BasePunishActionModel, {
    discriminator: {
      property: 'type',
      subTypes: PunishActionSubTypes
    },
    keepDiscriminatorProperty: true,
  })
  warn_action!: BasePunishActionModel

  notice_before_ban_count!: number
  enter_user_fix_time!: number
  recheck_interval!: number
  check_name_change!: boolean

  username_list!: BlackWhiteListItem<RawChipItem>
  fio_list!: BlackWhiteListItem<RawChipItem>

  username_enabled!: boolean
  username_required_enabled!: boolean

  username_min_len!: number
  username_max_len!: number

  fio_enabled!: boolean
  fio_min_len!: number
  fio_max_len!: number
  fio_rtl_enabled!: number
  fio_rtl_percent!: number
  fio_hieroglyph_enabled!: boolean
  need_profile_photo!: boolean

  check_user_profile!: boolean
  profile_stop_word_list!: Array<RawChipItem>
  block_profile_with_channel!: boolean
  profile_channel_min_subscribers!: boolean
  check_profile_stop_word_list_in_channel!: boolean
  check_profile_stop_word_list_in_channel_title!: boolean

  fio_hieroglyph_percent!: number
  fio_zalgo_enabled!: boolean

  journal!: boolean

  folder_invite_link_enabled!: boolean
}
