import store from "@/store/store";
import { defaultButtonBuilders } from "@/includes/buttons";

import EditPostActionBuilder from 'piramis-base-components/src/shared/modules/posting/PostActions/ActionTypes/EditPostAction/EditPostActionBuilder'
import { FileType } from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Media/types";

export default class EditPostActionBuilderCustom extends EditPostActionBuilder {
    actionProps = () => ({
        mediaUploadTarget: JSON.stringify({ type: 'board', token: store.getters.currentBoardKey }),
        buttons: defaultButtonBuilders(),
        editorButtonsHelpView: () => import('@/includes/helpViews/EditorButtonsHelpView.vue'),
        blacklistMediaButtonTypes: store.getters.isCurrentBoardBrand ? undefined : [ FileType.Animation, FileType.Video, FileType.Voice, FileType.VideoNote, FileType.Document, FileType.Audio, FileType.Image ]
    })
}
