var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"facecontrol-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.descriptionKey) ? _vm.$t(_vm.module.model.descriptionKey): ''},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_facecontrol_area_title')}},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'check_name_change',
          'hasAnchor': true,
          'disabled': _vm.disabled,
        }
      }}}),(_vm.module.model.params)?_c('nested-content',[_c('punish-action-setting',{attrs:{"title-key":"ban_action","punish-action-types":_vm.punishActionTypes,"message-editor-placeholders":_vm.FACE_CONTROL_PLACEHOLDERS,"disabled":_vm.disabled},model:{value:(_vm.module.model.params.ban_action),callback:function ($$v) {_vm.$set(_vm.module.model.params, "ban_action", $$v)},expression:"module.model.params.ban_action"}}),_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'notice_before_ban_count',
            'hasAnchor': true,
            'settingWrapper': {
              disabledValue: 0,
              hasFieldButton: true,
              customiseValue: 3
            },
            'disabled': _vm.disabled,
          }
        }}}),(_vm.module.model.params.notice_before_ban_count > 0)?_c('nested-content',[_c('punish-action-setting',{staticClass:"punish-action-nested",attrs:{"title-key":"warn_action","punish-action-types":_vm.punishActionTypes,"message-editor-placeholders":_vm.FACE_CONTROL_PLACEHOLDERS,"disabled":_vm.disabled},model:{value:(_vm.module.model.params.warn_action),callback:function ($$v) {_vm.$set(_vm.module.model.params, "warn_action", $$v)},expression:"module.model.params.warn_action"}}),_c('time-unit-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'enter_user_fix_time',
              'step': _vm.StepUnit.Second,
              'hasAnchor': true,
              'settingWrapper': {
                value: 0,
                hasFieldButton: true,
                customiseValue: _vm.StepUnit.Minute,
              },
              'disabled': _vm.disabled,
            }
          }}})],1):_vm._e()],1):_vm._e(),_c('time-unit-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.setRecheckTime,
        'args': {
          'model': _vm.module.model.params,
          'units': [_vm.StepUnit.Minute, _vm.StepUnit.Hour, _vm.StepUnit.Day, _vm.StepUnit.Week, _vm.StepUnit.Month],
          'key': 'recheck_interval',
          'tariffTags': _vm.$store.getters.isChatSet ? _vm.advancedLicenseTag : null,
          'hasAnchor': true,
          'settingWrapper': {
            disabledValue: 0,
            customiseValue: _vm.StepUnit.Hour * 2,
            hasFieldButton: true
          },
          'disabled': _vm.disabled,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_facecontrol_rules_title')}},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'need_profile_photo',
          'hasAnchor': true,
          'tariffTags': _vm.$store.getters.isChatSet ? _vm.brandTag : null,
          'disabled': _vm.disabled,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'folder_invite_link_enabled',
          'hasAnchor': true,
          'disabled': _vm.disabled,
        }
      }}}),_c('a-divider'),_c('typed-black-white-list-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'username_list',
          'hasAnchor': true,
          'possibleChipTypeInfos': _vm.BASE_SUPER_LIST_TYPES,
          'settingWrapper': {
            'hasFieldButton': true,
            'disabledValue': {
              type: 'Blacklist',
              items: [ ]
            },
            'customiseValue': {
              type: 'Blacklist',
              items: [ ]
            },
          },
          'disabled': _vm.disabled,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'username_enabled',
          'hasAnchor': true,
          'disabled': _vm.disabled,
        }
      }}}),(_vm.module.model.params.username_enabled)?_c('nested-content',[_c('switch-input',{directives:[{name:"hide-once",rawName:"v-hide-once",value:(!this.module.model.params.username_required_enabled),expression:"!this.module.model.params.username_required_enabled"}],attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'username_required_enabled',
            'hasAnchor': true,
            'disabled': _vm.disabled,
          }
        }}}),_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'username_min_len',
            'hasAnchor': true,
            'min': 0,
            'settingWrapper': {
              customiseValue: 5,
              hasFieldButton: true,
              disabledValue: 0,
            },
            'disabled': _vm.disabled,
          }
        }}}),_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'username_max_len',
            'hasAnchor': true,
            'min': 0,
            'settingWrapper': {
              customiseValue: 24,
              hasFieldButton: true,
              disabledValue: 0
            },
            'disabled': _vm.disabled,
          }
        }}})],1):_vm._e(),_c('a-divider'),_c('typed-black-white-list-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'fio_list',
          'hasAnchor': true,
          'possibleChipTypeInfos': _vm.BASE_SUPER_LIST_TYPES,
          'settingWrapper': {
            'disabledValue': {
              type: 'Blacklist',
              items: [ ]
            },
            'customiseValue': {
              type: 'Blacklist',
              items: [ ]
            },
          },
          'disabled': _vm.disabled,

        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'fio_enabled',
          'hasAnchor': true,
          'disabled': _vm.disabled,
        }
      }}}),(_vm.module.model.params.fio_enabled)?_c('nested-content',[_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'fio_link_enabled',
            'hasAnchor': true,
            'disabled': _vm.disabled,
          }
        }}}),_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'fio_min_len',
            'hasAnchor': true,
            'min': 0,
            'settingWrapper': {
              disabledValue: 0,
              hasFieldButton: true,
              customiseValue: 3
            },
            'disabled': _vm.disabled,
          }
        }}}),_c('number-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'fio_max_len',
            'hasAnchor': true,
            'min': 0,
            'settingWrapper': {
              disabledValue: 0,
              hasFieldButton: true,
              customiseValue: 26
            },
            'disabled': _vm.disabled,
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'fio_zalgo_enabled',
            'hasAnchor': true,
            'disabled': _vm.disabled,
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'fio_rtl_enabled',
            'hasAnchor': true,
            'disabled': _vm.disabled,
          }
        }}}),(_vm.module.model.params.fio_rtl_enabled)?_c('nested-content',[_c('number-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'fio_rtl_percent',
              'hasAnchor': true,
              'min': 0,
              'max': 100,
              'formatter': function (value) { return (value + "%"); },
              'parser': function (value) { return value.replace('%', ''); },
              'settingWrapper': {
                value: 0,
                customiseValue: 10
              },
              'disabled': _vm.disabled,
            }
          }}})],1):_vm._e(),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'fio_hieroglyph_enabled',
            'hasAnchor': true,
            'disabled': _vm.disabled,
          }
        }}}),(_vm.module.model.params.fio_hieroglyph_enabled)?_c('nested-content',[_c('number-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'fio_hieroglyph_percent',
              'hasAnchor': true,
              'min': 0,
              'max': 100,
              'formatter': function (value) { return (value + "%"); },
              'parser': function (value) { return value.replace('%', ''); },
              'settingWrapper': {
                value: 0,
                customiseValue: 10
              },
              'disabled': _vm.disabled,
            }
          }}})],1):_vm._e()],1):_vm._e(),_c('a-divider'),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'check_user_profile',
          'hasAnchor': true,
          'tariffTags': _vm.getTagsByFieldKey('check_user_profile'),
          'disabled': _vm.disabled,
        },
      }}}),(_vm.module.model.params.check_user_profile)?_c('nested-content',[_c('typed-black-white-list-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'profile_stop_word_list',
            'hasAnchor': true,
            'possibleChipTypeInfos': _vm.BASE_SUPER_LIST_TYPES,
            'settingWrapper': {
              'disabledValue': {
                type: 'Blacklist',
                items: [ ]
              },
              'customiseValue': {
                type: 'Blacklist',
                items: [ ]
              },
            },
            'disabled': _vm.disabled,
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'block_profile_with_channel',
            'hasAnchor': true,
            'disabled': _vm.disabled,
          }
        }}}),(_vm.module.model.params.block_profile_with_channel)?_c('nested-content',[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'profile_channel_min_subscribers',
              'hasAnchor': true,
              'min': 0,
              'settingWrapper': {
                value: 0,
                customiseValue: 100
              },
              'tariffTags': _vm.getTagsByFieldKey('profile_channel_min_subscribers'),
              'disabled': _vm.disabled,
            }
          }}}),_c('switch-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'check_profile_stop_word_list_in_channel',
              'hasAnchor': true,
              'tariffTags': _vm.getTagsByFieldKey('check_profile_stop_word_list_in_channel'),
              'disabled': _vm.disabled,
            }
          }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'check_profile_stop_word_list_in_channel_title',
              'hasAnchor': true,
              'tariffTags': _vm.getTagsByFieldKey('check_profile_stop_word_list_in_channel'),
              'disabled': _vm.disabled,
            }
          }}})],1):_vm._e()],1):_vm._e()],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'journal',
          'prefix': 'facecontrol_handler_module_',
          'hasAnchor': true,
          'disabled': _vm.disabled,
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }