


































































import AnchorCard from '@/components/AnchorCard.vue'
import AnchorsHandlerModuleBuilder
  from '../../../includes/logic/Modules/models/modules/AnchorsHandlerModule/AnchorsHandlerModuleBuilder'
import AnchorData from '@/includes/logic/Anchors/AnchorData'
import { createAnchor, frontendAnchors, rawAnchors } from '@/includes/logic/Anchors/anchors'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'

import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { Component, Mixins, VModel } from 'vue-property-decorator'
import { debounce } from 'lodash'

@Component({
  components: {
    NotAvailableOptionsOverlay,
    AnchorCard,
    List,
  },
  data() {
    return {
      ListDirection
    }
  }
})
export default class AnchorsHandlerModuleView extends Mixins<ModuleBindings, TariffsTagsHelper>(ModuleBindings, TariffsTagsHelper) {
  @VModel() module!: AnchorsHandlerModuleBuilder

  anchors: Array<IterableListItem<AnchorData>> = []

  get tariffTag() {
    return this.module.tariffTags()
  }

  onAnchorsChange = debounce(() => {
    this.saveAnchors()
  }, 300)

  saveAnchors(): void {
    this.module.model.params.anchors = rawAnchors(this.anchors)
  }

  updateAnchors(): void {
    this.anchors = frontendAnchors(this.module.model.params.anchors)
  }

  createNewAnchor() {
    this.anchors.unshift(createAnchor())

    this.saveAnchors()
  }

  deleteAnchor(guid: string) {
    this.$confirm({
      title: this.$t("dialog_anchors_delete_item_title").toString(),
      content: this.$t("dialog_anchors_delete_item_text").toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        const idx = this.anchors.findIndex(a => a.guid === guid)

        if (idx !== -1) {
          this.anchors.splice(idx, 1)

          this.saveAnchors()
        }
      },
    })
  }

  created() {
    this.updateAnchors()
  }
}
