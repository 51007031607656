var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mute-media-action-view"},[_c('punish-message',{attrs:{"default-model":_vm.defaultModel ? _vm.defaultModel.message : []},model:{value:(_vm.model.message),callback:function ($$v) {_vm.$set(_vm.model, "message", $$v)},expression:"model.message"}}),_c('punish-time-wrapper',{attrs:{"has-max-time-message":_vm.hasMaxTimeMessage},model:{value:(_vm.model.time),callback:function ($$v) {_vm.$set(_vm.model, "time", $$v)},expression:"model.time"}}),_c('switch-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'delete_message',
        'hasAnchor': true
      }
    }}}),(_vm.model.delete_message > 0)?_c('nested-content',{staticClass:"mb-0"},[_c('time-unit-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'delete_message_delay',
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: _vm.StepUnit.Minute,
            hasFieldButton: true,
            disabledValue: 0,
          }
        }
      }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }