var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summarize-message-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('a-card',[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'enable_daily_digest',
          'disabled': _vm.disabled
        }
      }}}),(_vm.module.model.params.enable_daily_digest)?_c('nested-content',[_c('time-picker-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'daily_digest_time',
            'clearable': false,
            'time24hr': true,
            'disabled': _vm.disabled
          }
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'daily_digest_message',
            'placeholders': _vm.BASE_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramBase,
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'hasAnchor': true,
            'disabled': _vm.disabled
          },
        }}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }