










































































































































































































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'
import Placeholders from '@/mixins/placeholders/placeholders'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import ModerateCommandHandlerModuleBuilder from '@/includes/logic/Modules/models/modules/ModerateCommandHandlerModule/ModerateCommandHandlerModuleBuilder'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import ModerateCommandHandlerModule, {
  BanActionTypesEnum
  } from "@/includes/logic/Modules/models/modules/ModerateCommandHandlerModule/ModerateCommandHandlerModule";
import NetworksModerateCommandHandlerModule
  from "@/includes/logic/Modules/models/modules/NetworksModerateCommandHandlerModule/NetworksModerateCommandHandlerModule";

import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      BanActionTypesEnum,
      NetworksModerateCommandHandlerModule,
      ModerateCommandHandlerModule,
      MediaEditorMode,
    }
  },
  components: {
    ModuleConfigSettingsWrapper,
    NestedContent,
    SelectInput,
    ModuleConfigSettings,
    MultiMessageEditorWithMediaInput,
    SwitchInput
  }
})
export default class ModerateCommandHandlerModuleView extends Mixins(InputSetups, Placeholders, TariffsTagsHelper, ModuleBindings) {
  @VModel() module!: ModerateCommandHandlerModuleBuilder

  get banActionTypesOptions(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('ban_command_remove_action_all_option').toString(),
        value: BanActionTypesEnum.All,
        tags: this.getTagsByFieldKey('ban_command_remove_action_all'),
        disabled: this.getTagsByFieldKey('ban_command_remove_action_all') !== null
      },
      {
        label: this.$t('ban_command_remove_action_reply_option').toString(),
        value: BanActionTypesEnum.Reply,
      },
      {
        label: this.$t('ban_command_remove_action_none_option').toString(),
        value: BanActionTypesEnum.None,
      }

    ]
  }
}
