import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

export default class BotApi {
  static async getPostBoards(type: 'tg') {
    return PostApi.sendRequest(type, 'getpostboards')
  }

  static async getBoardConfig(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'getpostboardconfig', body)
  }

  static async setBoardConfig(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'setpostboardconfig', body)
  }
}
