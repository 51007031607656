import AnchorData from "@/includes/logic/Anchors/AnchorData";

import { IMessageTemplate } from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import { IterableItemFactory, IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { plainToInstance } from "class-transformer";

export type IAnchorData = {
  trigger?: string
  triggers: Array<string>
  reaction: Array<IMessageTemplate>
  delete_previous: boolean
  enabled: boolean
  strict: boolean
  send_as_reply: boolean
  remove_request: boolean
}

const iterableItemFactory = new IterableItemFactory()

export function getAnchorInstance() {
  let anchor: IAnchorData = {
    triggers: [],
    reaction: [],
    delete_previous: false,
    enabled: false,
    strict: false,
    send_as_reply: false,
    remove_request: false,
  }

  return plainToInstance(AnchorData, anchor)
}

export function frontendAnchors(anchors: Array<AnchorData>) {
  return anchors.map(a => iterableItemFactory.create(a))
}

export function rawAnchors(items: Array<IterableListItem<AnchorData>>) {
  return items.map(i => i.value)
}

export function createAnchor() {
  return iterableItemFactory.create(getAnchorInstance())
}
