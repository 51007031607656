import { InvoiceInfo, InvoiceInfoWithTargets } from "piramis-base-components/src/plugins/AdminTemplate/includes/types/Shared/InvoiceInfo.types";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

export default class RecurringInvoicesApi {
  static async getRecurringInvoices(): AxiosPromise<{ invoices: Array<InvoiceInfoWithTargets> }> {
    return PostApi.sendRequest('tg', 'getrecurringinvoices', {})
  }

  static async deleteRecurringInvoice(invoice: InvoiceInfo['invoice_number']): AxiosPromise<any> {
    return PostApi.sendRequest('tg', 'deleterecurringinvoice', { invoice })
  }
}
