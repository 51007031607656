import { EditPublishedPost } from "@/views/posts-planner/posts.types";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise, AxiosRequestConfig } from "axios";

export default class PostsApi {
  static async editPublishedPost(type: 'tg', body: EditPublishedPost): AxiosPromise<void> {
    return PostApi.sendRequest(type, 'editpublishedpost', body)
  }

  static async createPost(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'createpost', body)
  }

  static async testPost(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'testpost', body)
  }

  static async getPostPreview(type: 'tg', body: any, config: AxiosRequestConfig) {
    return PostApi.sendRequest(type, 'getpostpreview', body, config)
  }

  static async getSavedPosts(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'getsavedposts', body)
  }

  static async deleteSavedPost(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'deletesavedpost', body)
  }

  static async getPosts(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'getposts', body)
  }

  static async getPostInfo(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'getpostinfo', body)
  }

  static async getOriginalPost(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'getoriginalpost', body)
  }

  static async deletePost(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'deletepost', body)
  }

  static async savePost(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'savepost', body)
  }

  static async movePost(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'movepost', body)
  }
}
