




















































































































































import { InputSetups } from '@/mixins/input-setups'
import { Style } from '@/views/posts-planner/posts.types'

import { MultiMessageEditorWithMediaData } from 'piramis-base-components/src/components/MultiMessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import { FileType } from 'piramis-base-components/src/components/File/types'
import { MessageEditorWithMediaTab } from "piramis-base-components/src/components/NewMessageEditors/types";
import MultiMessageEditorWithMediaInput
  from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";

import { Mixins, Prop, VModel, Watch, } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { cloneDeep } from "lodash";
import { BRAND_TAG } from "@/includes/constants";

@Component({
  data() {
    return {
      FileType
    }
  },
  components: {
    MultiMessageEditorWithMediaInput
  }
})
export default class PostStylesSetup extends Mixins(InputSetups, UseFields) {
  @VModel({ 'type': Object }) style !: Style

  @Prop({ required: true, type: String }) board!: string

  file: any = null

  styleCopy: Style | null = null

  @Watch('styleCopy', { deep: true })
  onStyleCopyChange(value: Style) {
    this.style = value
  }

  inputSetup(args: FieldData): FieldData {
    if ([ 'postfix_message', 'prefix_message' ].includes(args.key)) {
      (args as FieldData & MultiMessageEditorWithMediaData).availableTabs = [ MessageEditorWithMediaTab.EditorTab, MessageEditorWithMediaTab.TextareaTab ]
      // (args as FieldData & MultiMessageEditorWithMediaData).settingsStructure = {
      // 'disable_link_preview': false,
      // 'disable_notify': false,
      // 'pin': false,
      // 'remove_previous': false,
      // }
    }

    args.setter = (value: any): void => {
      this.$set(args.model, args.key as string, value)
    }
    return args
  }

  onSelectChange(): void {
    if (this.styleCopy) {
      this.$set(this.styleCopy, 'watermark_data', '')
    }
  }

  get ultimateTag() {
    return this.$store.getters.isCurrentBoardBrand ? null : BRAND_TAG
  }

  get watermarkTypes(): Array<SelectOption> {
    return [
      { 'label': this.$t('board_style_watermark_none'), 'value': 'None' },
      { 'label': this.$t('board_style_watermark_text'), 'value': 'Text' },
      { 'label': this.$t('board_style_watermark_file'), 'value': 'File' },
    ]
  }

  get watermarkPosition(): Array<SelectOption> {
    return [
      { 'label': this.$t('watermark_position_flip'), 'value': 'Flip' },
      { 'label': this.$t('watermark_position_center'), 'value': 'Center' },
      { 'label': this.$t('watermark_position_top_right'), 'value': 'TopRight' },
      { 'label': this.$t('watermark_position_top_left'), 'value': 'TopLeft' },
      { 'label': this.$t('watermark_position_bottom_right'), 'value': 'BottomRight' },
      { 'label': this.$t('watermark_position_bottom_left'), 'value': 'BottomLeft' },
    ]
  }

  created() {
    if (this.style) this.styleCopy = cloneDeep(this.style)
  }
}
