



















































































import SetPeriod from '@/views/chat/statistics/components/mainStatistics/setPeriod.vue'
import UserStatGlobalCard from '@/components/UsersStatByPeriod/UserStatGlobalCard.vue'
import { errorNotification } from '@/includes/NotificationService'
import UserStatUsersData from '@/components/UsersStatByPeriod/UserStatUsersData.vue'
import { InputSetups } from '@/mixins/input-setups'
import { StatPeriodGroup, StatPeriodMetrics } from '@/includes/types/UsersStatByPeriod/Enums'
import { PeriodMetricsFields, UsersStatCategory } from '@/includes/types/UsersStatByPeriod/types'
import TagsSelect from '@/components/UsersStatByPeriod/TagsSelect.vue'
import { requestData } from '@/components/UsersStatByPeriod/UsersAnalyseService'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'

import { SelectOption } from 'piramis-base-components/src/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component, Mixins, Prop } from 'vue-property-decorator'
import { snakeCase } from 'lodash'
import moment from 'moment'

@Component({
  components: {
    TagsSelect,
    UserStatUsersData,
    UserStatGlobalCard,
    SetPeriod,
    EmptyData,
    NotAvailableOptionsOverlay
  }
})
export default class UsersStatByPeriod extends Mixins<UseFields, InputSetups, TariffsTagsHelper>(UseFields, InputSetups, TariffsTagsHelper) {
  @Prop({ type: Boolean, default: true, required: false }) showGlobal!:Boolean

  period: {from:string, to: string} = {
    from: moment().add(-3, 'month').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  }

  selectedMetrics: Array<StatPeriodMetrics> = [ StatPeriodMetrics.MessageCount, StatPeriodMetrics.ReplyCount, StatPeriodMetrics.ReputationTake ]

  group: StatPeriodGroup = StatPeriodGroup.WEEKS

  category: UsersStatCategory | null = null

  updateChartKey = 0

  get categoryOptions():Array<SelectOption> {
    return Object.keys(UsersStatCategory).map(key => {
      return { label: this.$t(`period_category_${ key.toLowerCase() }_title`).toString(), value: key.toLowerCase() }
    })
  }

  get periodGroupOptions():Array<SelectOption> {
    return Object.keys(StatPeriodGroup).map(key => {
      return { label: this.$t(`period_group_${ key.toLowerCase() }_title`).toString(), value: key }
    })
  }

  get metricOptions():Array<SelectOption> {
    return Object.keys(StatPeriodMetrics).map(key => {
      return { label: this.$t(`period_metric_${ key.toLowerCase() }_title`).toString(), value: key }
    })
  }

  get selectedMetricsInSnakeCase(): Array<keyof PeriodMetricsFields> {
    return this.selectedMetrics.slice().map(snakeCase) as Array<keyof PeriodMetricsFields>
  }

  handleCategoryChange(category:UsersStatCategory) {
    if (this.category === category) {
      this.category = null
    } else {
      this.category = category
    }

    this.requestStat()
  }

  handleChange(newState:StatPeriodGroup) {
    if (this.group === newState) {
      this.group = StatPeriodGroup.WEEKS
    } else {
      this.group = newState
    }

    this.requestStat()
  }

  validateRequest():Promise<boolean | string> {
    return new Promise<boolean>((resolve, reject) => {
      if (moment(this.period.from).diff(moment(this.period.to), 'd') < -370) {
        reject(this.$t('period_limit'))
      } else if (this.selectedMetrics.length <= 0) {
        reject(this.$t('empty_metrics_notification'))
      } else {
        resolve(true)
      }
    })
  }

  loaded = false

  getUserStatByPeriod() {
    this.loaded = false

    requestData({
      chat_id: Number.parseInt(this.$route.params[EntityTypes.CHAT_ID]),
      from: this.period.from,
      to: this.period.to,
      group: this.group,
      metrics: this.selectedMetrics,
      category: this.category === null ? undefined : this.category,
    })
      .catch(errorNotification)
      .finally(() => this.loaded = true)
  }

  requestStat():void {
    this.validateRequest()
      .then(this.getUserStatByPeriod)
      .catch(errorNotification)
  }

  created() {
    this.requestStat()
  }
}
