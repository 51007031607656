










import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue';

import Vue from 'vue'
import { Component } from "vue-property-decorator";

@Component({
  components: {
    ExtendedHelpMessage
  }
})
export default class EditorButtonsHelpView extends Vue{
}
