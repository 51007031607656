import { errorNotification } from "@/includes/NotificationService";
import { Board } from "@/views/posts-planner/posts.types";
import BotApi from "@/includes/Api/Bot.api";

export const getPostBoards = async (): Promise<Array<Board>> => {
  return BotApi.getPostBoards('tg')
      .then(({ data }) => {
        return data.boards
      })
      .catch((error) => {
        errorNotification(error)

        return []
  })
}
