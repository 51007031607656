import { render, staticRenderFns } from "./BanActionView.vue?vue&type=template&id=5a96ac0f&scoped=true&"
import script from "./BanActionView.vue?vue&type=script&lang=ts&"
export * from "./BanActionView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a96ac0f",
  null
  
)

export default component.exports