import { render, staticRenderFns } from "./DeleteMessageActionView.vue?vue&type=template&id=7f5a2d87&scoped=true&"
import script from "./DeleteMessageActionView.vue?vue&type=script&lang=ts&"
export * from "./DeleteMessageActionView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f5a2d87",
  null
  
)

export default component.exports