












































































































import { InputSetups } from '../mixins/input-setups'
import { IAnchorData } from "@/includes/logic/Anchors/anchors";
import Placeholders from "@/mixins/placeholders/placeholders";

import { FileType } from 'piramis-base-components/src/components/File/types'
import CheckboxInput from 'piramis-base-components/src/components/Pi/fields/CheckboxInput/CheckboxInput.vue';
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue';
import ChipsInput from 'piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue';

import { cloneDeep } from 'lodash'
import { Component, Mixins, VModel, Watch } from 'vue-property-decorator'

@Component({
  components: {
    CheckboxInput,
    MultiMessageEditorWithMediaInput,
    ChipsInput
  },
  data() {
    return {
      FileType
    }
  }
})
export default class AnchorCard extends Mixins(InputSetups, Placeholders) {
  @VModel({ 'type': Object }) anchorData!: IAnchorData

  anchorDataCopy: IAnchorData | null = null

  @Watch('anchorDataCopy', { 'deep': true })
  onAnchorDataCopyChange(value: IAnchorData): void {
    this.anchorData = value
  }

  created(): void {
    this.anchorDataCopy = cloneDeep(this.anchorData)
  }
}
