import { SendMessageReq, SendMessageRes } from "@/includes/types/SendMessage";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

export default class SendMessageApi {
  static async sendSelfMessage({ chat_id, message }: SendMessageReq): AxiosPromise<SendMessageRes> {
    return PostApi.sendRequest('tg', "sendselfmessage", { chat_id,
      message });
  }

  static async sendChatMessage({ chat_id, message }: SendMessageReq): AxiosPromise<SendMessageRes> {
    return PostApi.sendRequest('tg', "sendchatmessage", {
      chat_id,
      message,
    });
  }
}
