




































import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import TriggersMixin from "@/mixins/TriggersMixin";

import ToggleButton from "piramis-base-components/src/components/ToggleButton.vue";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionChance,
    ToggleButton,
    ConfigField
  },
})
export default class ChangeTriggerActiveStateActionSetupView extends Mixins<ActionSetupView, TriggersMixin>(ActionSetupView, TriggersMixin) {
}
