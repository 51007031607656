















































































































































import RefreshChats from '@/components/RefreshChats.vue'
import IfChatsEmpty from '@/components/IfChatsEmpty.vue'
import ChatCard from '@/components/chat/ChatCard.vue'
import { BaseChatInfo, BaseChatInfoNetworkData } from '@/includes/types/Chat/types'
import { getNetworkTitle, getPrivacyAlias } from "@/includes/logic/Networks/utils";
import NetworksHelperMixin from "@/includes/logic/Networks/NetworksHelperMixin";
import ChatApi from "@/includes/Api/Chat.api";
import { ChatsInfoStat, PreparedChatInfoStat } from "@/includes/types/ChatsInfoStat.types";
import { errorNotification } from "@/includes/NotificationService";

import AddEntityCard from 'piramis-base-components/src/components/AddEntityCard.vue'
import { fromToTicks } from "piramis-base-components/src/shared/utils/fromToTicks";
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList';

import moment from 'moment'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  methods: {
    getPrivacyAlias,
    getNetworkTitle
  },
  components: {
    RefreshChats,
    IfChatsEmpty,
    ChatCard,
    AddEntityCard,
    Icon
  }
})
export default class Chats extends Mixins(NetworksHelperMixin) {

  chatsStat: Record<number, PreparedChatInfoStat> = {}

  chatsWithNetworks: Array<{ network: BaseChatInfoNetworkData, chats: Array<BaseChatInfo> }> = []
  chatsWithoutNetworks: Array<BaseChatInfo> = []

  statisticsLoaded = false

  chatsLoading = false

  get filterChatsByMembers(): Array<BaseChatInfo> {
    return this.$store.getters.sortedVisibleChatsInfos
  }

  updateChatInfo(data: { id: number, diagnostic: BaseChatInfo['diagnostic'] }) {
    this.$store.commit('updateChatInfo', {
      chatId: data.id,
      config: { diagnostic: data.diagnostic }
    })
  }

  async prepareChatsStatistics(data: ChatsInfoStat['chats_stat']) {
    const chatsStatistics = data.reduce<Record<number, Promise<PreparedChatInfoStat>>>((acc, value) => {
      acc[value.chat_id] = seriesByMetricInList(
        this.$i18n,
        value.activity,
        [ 'users', 'msgs' ],
        fromToTicks(moment().add(-7, 'days').startOf('day').format(), moment().format(), { format: 'YYYY-MM-DD HH:mm:ss' })
      )
        .calcSeries([
          { name: this.$t('chat_card_statistics_users').toString() },
          { name: this.$t('chat_card_statistics_msgs').toString() },
        ])
        .then(res => {
          return {
            leave: value.leave,
            new: value.new,
            total: value.total,
            series: res,
          }
        })

      return acc
    }, {})

    const entries = Object.entries(chatsStatistics)

    const resolvedValues = await Promise.all(entries.map(([ _, promise ]) => promise));
    return Object.fromEntries(entries.map(([ chatId ], index) => [ chatId, resolvedValues[index] ]));
  }

  gotoAddGroupLink() {
    window.open('https://t.me/chatkeeperbot?startgroup&admin=restrict_members+delete_messages+invite_users+pin_messages+manage_topics', '_blank')
  }

  getNetworksInfo() {
    const chatsWithoutNetworks: Array<BaseChatInfo> = []
    const chatsWithNetworks: Array<{ network: BaseChatInfoNetworkData, chats: Array<BaseChatInfo> }> = []

    ;(this.filterChatsByMembers ?? []).forEach(chat => {
      if (!chat.networks.length) {
        chatsWithoutNetworks.push(chat)
      } else {
        chat.networks.forEach(n => {
          const existedGn = chatsWithNetworks.find(gn => gn.network.id === n.id)

          if (!existedGn) {
            chatsWithNetworks.push({
              network: n,
              chats: [ chat ]
            })
          } else {
            existedGn.chats.push(chat)
          }
        })
      }
    })

    this.chatsWithNetworks = chatsWithNetworks.sort((a, b) => b.chats.length - a.chats.length)
    this.chatsWithoutNetworks = chatsWithoutNetworks
  }

  getChatsInfoStatRequest() {
    return ChatApi.getChatsInfoStat()
      .then(({ data }) => data.chats_stat)
      .catch(error => {
        errorNotification(error)

        this.statisticsLoaded = false
      })
  }

  getChatsInfo() {
    const lsChatsInfoStatKey = 'chats_info_stat'
    const chatsInfoString = localStorage && localStorage.getItem(lsChatsInfoStatKey)
    let chat_info_stat: { data: PreparedChatInfoStat, time: number } | undefined;

    if (chatsInfoString) {
      chat_info_stat = JSON.parse(chatsInfoString)
    }

    const ONE_HOUR = 1000 * 3600

    if (chat_info_stat && ((Date.now() - chat_info_stat.time) < ONE_HOUR)) {
      this.chatsStat = chat_info_stat.data

      this.statisticsLoaded = true
    } else {
      this.getChatsInfoStatRequest()
        .then(data => {
          this.prepareChatsStatistics(data ?? [])
            .then(res => {
              this.chatsStat = res

              if (localStorage) {
                localStorage.setItem(lsChatsInfoStatKey, JSON.stringify({
                  data: res,
                  time: Date.now(),
                }))
              }
            })
            .catch(errorNotification)
            .finally(() => this.statisticsLoaded = true)
        })
    }
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()

    this.getChatsInfo()

    this.getNetworksInfo()
  }
}
