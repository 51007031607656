





















import { InputSetups } from "@/mixins/input-setups";
import Placeholders from "@/mixins/placeholders/placeholders";
import {
  PunishActionEditorButtonsKey,
  PunishActionEditorPlaceholdersKey,
  PunishActionEditorPrefix
} from "@/includes/logic/Modules/di";

import MultiMessageEditorWithMediaInput from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import {
  PlaceholdersOnes,
  PlaceholdersStack
} from "piramis-base-components/src/components/Editor/components/Toolbar/types";
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import {
  IMessageTemplate
} from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Component, Inject, Mixins, Prop, VModel } from 'vue-property-decorator';

@Component({
  components: {
    MultiMessageEditorWithMediaInput
  }
})
export default class PunishMessage extends Mixins(InputSetups, Placeholders, ModelSetter) {
  @VModel() punishMessage!: Array<IMessageTemplate>

  @Prop() validationRules!: string

  @Prop({ default: () => [] }) defaultModel!: Array<IMessageTemplate>

  @Inject(PunishActionEditorPlaceholdersKey) placeholders!: PlaceholdersOnes | PlaceholdersStack

  @Inject(PunishActionEditorButtonsKey) buttons!: PlaceholdersOnes | PlaceholdersStack

  @Inject(PunishActionEditorPrefix) prefix!: string

  get buttonsOrBase() {
    if (this.buttons) {
      return this.buttons
    }

    return this.newFilterEditorButtons
  }

  get placeholdersOrBase() {
    if (this.placeholders) {
      return this.placeholders
    }

    return this.PUNISH_MESSAGE_PLACEHOLDERS
  }
}
