import { DemoteAdminReq, GetAdminPermsReq, GetAdminPermsRes, PromoteAdminReq } from "@/includes/types/PromoteAdmins";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

export class PromoteAdminsApi {
  static async demoteAdmin(type: 'tg', { chat_id, user_id }: DemoteAdminReq): AxiosPromise<any> {
    return PostApi.sendRequest(type, 'demoteadmin', { chat_id, user_id })
  }

  static async promoteAdmin(type: 'tg', body: PromoteAdminReq): AxiosPromise<any> {
    return PostApi.sendRequest(type, 'promoteadmin', body)
  }

  static async getAdminPerms(type: 'tg', body: GetAdminPermsReq): AxiosPromise<GetAdminPermsRes> {
    return PostApi.sendRequest(type, 'getadminperms', body)
  }
}
