










import PunishMessage from "@/components/Modules/punish-actions/components/PunishMessage.vue";
import { NotifyAction } from "@/includes/logic/Modules/models/PunishActions";
import { ClassProperties } from "@/includes/utils/types";

import { Vue, Component, VModel, Prop } from 'vue-property-decorator';

@Component({
  components: {
    PunishMessage
  }
})
export default class NotifyActionView extends Vue {
  @VModel() model!: NotifyAction

  @Prop() defaultModel!: Partial<ClassProperties<NotifyAction>>
}
