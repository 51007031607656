var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"period-settings"},[_c('time-unit-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model.period,
        'key': 'interval',
        'step': _vm.StepUnit.Minute,
        'validation': 'required|min_value:1',
        'prefix': 'post_',
        'units': [ _vm.StepUnit.Minute, _vm.StepUnit.Day, _vm.StepUnit.Hour, _vm.StepUnit.Week, _vm.StepUnit.Month ],
        'settingWrapper': {
          customiseValue: _vm.StepUnit.Day / 60,
          hasFieldButton: true,
          disabledValue: 0
        }
      },
    }}}),(_vm.model.period && _vm.model.period.interval > 0)?_c('post-period-conditions',{attrs:{"conditions":_vm.model.period.conditions},on:{"update-conditions":function($event){_vm.model.period.conditions = $event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }