














































































































import TelegramProfileButton from "@/components/TelegramProfileButton.vue";
import AddAdminModal from "@/components/AddAdmin/components/AddAdminModal.vue";
import AddAdminsMixin from "@/components/AddAdmin/AddAdminsMixin";
import { FullAddAdminRequest, SitePermissionEnum, UsersResultResponse } from "@/includes/types/AddAdmin/types";
import PermissionsBanner from "@/components/PermissionsBanner.vue";

import { atSignedLogin } from "piramis-base-components/src/shared/utils/tgLoginHelpers";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import MiniHelpMessage from "piramis-base-components/src/components/MiniHelpMessage/MiniHelpMessage.vue";

import { Component, Mixins } from 'vue-property-decorator';

@Component({
  data() {
    return {
      SitePermissionEnum
    }
  },
  methods: {
    atSignedLogin
  },
  components: {
    PermissionsBanner,
    ConfigField,
    MiniHelpMessage,
    AddAdminModal,
    TelegramProfileButton
  }
})
export default class SiteAdmins extends Mixins(AddAdminsMixin) {
  requestModel: FullAddAdminRequest = this.siteAdminsModel();

  activeUserId: string | null = null;

  get canAddSiteAdmins() {
    return this.$store.getters.canAddSiteAdmins
  }

  get hasAdmins() {
    return this.$store.state.chatState.chat && !!Object.keys(this.$store.state.chatState.chat.adminsMap).length
  }

  get permissionsMap() {
    if (this.$store.getters.isChatSet) {
      return this.$store.state.chatState.chat!.adminPermissionsMap
    }
  }

  handleUpdateUsers(_: UsersResultResponse['users_map']) {
    this.$store.dispatch('requestChatConfig', { id: this.$store.getters.chatId })
      .then(res => {
        this.modal = false
      })
  }

  prepareForEdit(id: string) {
    if (this.permissionsMap[+id]) {
      const u = this.permissionsMap[+id]

      this.activeUserId = id

      this.requestModel.permissions = [ ...u.permissions ]
      this.requestModel.username = u.login

      this.modal = true
    }
  }

  siteAdminsModel(): FullAddAdminRequest {
    return {
      ...this.defaultModel(),
      permissions: []
    }
  }

  removeAdmin(login: string, id: string) {
    this.$confirm({
      title: this.$t("remove_user_confirm_title").toString(),
      content: this.$t("remove_user_confirm_text", [ login ]).toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.onRemoveClick(id)
      }
    })
  }
}
