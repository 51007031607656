import {
  AddAdminByLinkRequest,
  AddAdminByLinkResponse, UsersResultResponse,
  AdminState,
  BindState,
  FullAddAdminRequest
} from "@/includes/types/AddAdmin/types";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

export class AddAdminApi {
  static async addAdminByName(type:'tg', body: FullAddAdminRequest): AxiosPromise<UsersResultResponse> {
    return PostApi.sendRequest(type, 'addadminbyname', body)
  }

  static async deleteAdmin(type: 'tg', body: FullAddAdminRequest): AxiosPromise<UsersResultResponse> {
    return PostApi.sendRequest(type, 'deleteadmin', body)
  }

  static async addAdminByLink(type: 'tg', body: AddAdminByLinkRequest): AxiosPromise<AddAdminByLinkResponse> {
    return PostApi.sendRequest(type, 'addadminbylink', body)
  }

  static async stateAdminByCode(type: 'tg', { token }: AdminState): AxiosPromise<BindState> {
    return PostApi.sendRequest(type, 'stateadminbycode', { token })
  }
}
