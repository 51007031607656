import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import store from "@/store/store";
import { ADVANCED_TAG } from "@/includes/constants";
import RegularExpressionFilterHandlerModule from "./RegularExpressionFilterHandlerModule";

import { TagData } from "piramis-base-components/src/components/Tags/types";
import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import {
  BanDeleteMessageBuilder,
  EditDeleteMessageBuilder,
  FirstMessageDeleteMessageBuilder,
  NewLimitDeleteMessageBuilder, WarnDeleteMessageBuilder
} from "../FilterPunishActions";

export default class RegularExpressionFilterHandlerModuleBuilder extends ModuleBuilder {
  model!: RegularExpressionFilterHandlerModule;

  group: ModuleGroupEnum = ModuleGroupEnum.MessagesModeration
  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: "spellcheck",
    color: "rgba(var(--a-danger), 1)"
  };
  view = () => import('@/components/Modules/modules/Filters/RegularExpressionFilterHandlerModuleView.vue')

  aliases = [ 'номер', 'фильтры', 'удалять', 'удалить', ]

  tariffTags(): Array<TagData> | null {
    return store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.isChatAtLeastAdvanced) ? ADVANCED_TAG : null
  }

setBuilderModel(moduleData: BaseModule) {
    super.setBuilderModel(moduleData);

    this.customPunishBuilders = {
      remove_edited_punish_action: [
        new EditDeleteMessageBuilder(this.model.filterType)
      ],
      ban_punish_action: [
        new BanDeleteMessageBuilder(this.model.filterType)
      ],
      first_message_punish_action: [
        new FirstMessageDeleteMessageBuilder(this.model.filterType)
      ],
      new_limit_punish_action: [
        new NewLimitDeleteMessageBuilder(this.model.filterType)
      ],
      warning_punish_action: [
        new WarnDeleteMessageBuilder(this.model.filterType)
      ]
    }
  }
}
