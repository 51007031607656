import FormsApi from "@/includes/Api/Forms.api";

import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";

import { Vue, Component } from 'vue-property-decorator'
import { Route } from 'vue-router'

@Component
export default class FormsHelper extends Vue {
  deleteForm(chatId: number, guid: string) {
    return FormsApi.deleteForm("tg", {
      guid   : guid,
      chat_id: chatId,
    })
  }

  goToCreateNewFormPage(chatId: number,): Promise<Route>  {
    return this.$router.push({
      name  : "questionnaire_form",
      params: {
        [EntityTypes.CHAT_ID]        : String(chatId),
        actionType: "new",
      },
    })
  }

  goToSetupFormPage(chatId: number, guid: string): Promise<Route> {
    return this.$router.push({
      name  : "questionnaire_form",
      params: {
        [EntityTypes.CHAT_ID]        : String(chatId),
        actionType: "edit",
      },
      query : { formGuid: guid },
    });
  }

  goToWatchFormPage(chatId: number, guid: string): Promise<Route> {
    return this.$router.push({
      name  : "questionnaire_form",
      params: {
        [EntityTypes.CHAT_ID]        : String(chatId),
        actionType: "watch",
      },
      query : { formGuid: guid },
    });
  }

  gotoCopyFormPage(chatId: number, guid: string): Promise<Route> {
    return this.$router.push({
      name  : "questionnaire_form",
      params: {
        [EntityTypes.CHAT_ID]        : String(chatId),
        actionType: "copy",
      },
      query : { formGuid: guid },
    });
  }

}
