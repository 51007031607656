import chatAction     from "./chats/actions.ts";
import triggersAction from "./triggers/actions";
import networksActions from "./networks/actions";
import plannerActions  from "./postsPlanner/actions";

const actions = {
	// chat
	...chatAction,
	// triggers
	...triggersAction,
  	// posts planner
	...plannerActions,
	...networksActions,

};

export default actions;
