var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"voteban-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.descriptionKey) ? _vm.$t(_vm.module.model.descriptionKey) : null,"help-message":_vm.$te(_vm.module.model.pageHelpMessageKey) ? _vm.$t(_vm.module.model.pageHelpMessageKey) : null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('time-unit-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'timeout',
          'prefix': 'voteban_',
          'min': _vm.StepUnit.Minute / 2,
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: _vm.StepUnit.Minute,
            hasFieldButton: true,
            disabledValue: 0
          },
          'disabled': _vm.disabled
        },
      }}}),_c('number-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'limit',
          'prefix': 'voteban_',
          'min': 0,
          'hasAnchor': true,
          'settingWrapper': {
            disabledValue: 0,
            hasFieldButton: true,
            customiseValue: 100
          },
          'disabled': _vm.disabled
        }
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'votebanMessage',
          'placeholders': _vm.votebanMessagePlaceholders,
          'targetToUpload': _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramBase,
          'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.info/cabinet',
          'hasAnchor': true,
          'disabled': _vm.disabled
        },
      }}}),_c('punish-action-setting',{attrs:{"title-key":"voteban_punish_action","disabled":_vm.disabled},model:{value:(_vm.module.model.params.punish_action),callback:function ($$v) {_vm.$set(_vm.module.model.params, "punish_action", $$v)},expression:"module.model.params.punish_action"}}),_c('punish-action-setting',{attrs:{"title-key":"voteban_actor_punish_action","disabled":_vm.disabled},model:{value:(_vm.module.model.params.actor_punish_action),callback:function ($$v) {_vm.$set(_vm.module.model.params, "actor_punish_action", $$v)},expression:"module.model.params.actor_punish_action"}}),_c('text-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'punish_button',
          'prefix': 'voteban_',
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: '',
            buttonTitle: _vm.$t('customize'),
            hasFieldButton: true,
            disabledValue: ''
          },
          'disabled': _vm.disabled
        }
      }}}),_c('text-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'forgive_button',
          'prefix': 'voteban_',
          'hasAnchor': true,
          'settingWrapper': {
            buttonTitle: _vm.$t('customize'),
            customiseValue: '',
            hasFieldButton: true,
            disabledValue: ''
          },
          'disabled': _vm.disabled
        }
      }}})],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module,
          'key': 'toggleProtection',
          'prefix': 'voteban_',
          'disabled': _vm.disabled
        }
      }}}),(_vm.module.toggleProtection)?_c('nested-content',[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params.protection,
            'key': 'count',
            'prefix': 'voteban_',
            'min': 0,
            'hasAnchor': true,
            'settingWrapper': {
              disabledValue: 0,
              hasFieldButton: true,
              customiseValue: 100
            },
            'disabled': _vm.disabled
          }
        }}}),(_vm.module.model.params.protection && _vm.module.model.params.protection.count > 0)?_c('nested-content',[_c('time-unit-input',{staticClass:"mb-0 mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params.protection,
              'key': 'interval',
              'prefix': 'voteban_',
              'min': _vm.StepUnit.Minute / 2,
              'hasAnchor': true,
              'settingWrapper': {
                customiseValue: _vm.StepUnit.Minute,
                hasFieldButton: true,
                disabledValue: 0
              },
              'disabled': _vm.disabled
            },
          }}})],1):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }