import {
  BaseChatInfo, SetChatCommonConfigReq,
  ChatConfig,
  NewChatConfig,
  RunChatActionType
} from "@/includes/types/Chat/types";
import { ChatsInfoStat } from "@/includes/types/ChatsInfoStat.types";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

export default class ChatApi {

  static getChatConfig(type: 'tg', { chat_id }: Record<string, any>) {
    return PostApi.sendRequest(type, "getchatconfig", { chat_id });
  }

  static async getChatsInfo(): AxiosPromise<{chats: Array<BaseChatInfo>}> {
    return PostApi.sendRequest('tg', 'getchatsinfo')
  }

  static async getChatsInfoStat(): AxiosPromise<ChatsInfoStat> {
    return PostApi.sendRequest('tg', 'getchatsinfostat', {  })
  }

  static async runChatAction({ chat_id, action }: {chat_id: BaseChatInfo['chat_id'], action: RunChatActionType }): AxiosPromise<{ chat: ChatConfig }> {
    return PostApi.sendRequest('tg', 'runchataction', { chat_id, action })
  }

  static async generateAccessToken(chat_id: BaseChatInfo['chat_id']): AxiosPromise<{ access_token: string }> {
    return PostApi.sendRequest('tg',  'generateaccesstoken', { chat_id })
  }

  static async migrateChatToModules (chat_id: number): AxiosPromise<{ chat: NewChatConfig }> {
    return PostApi.sendRequest('tg', 'migratechattomodules', { chat_id })
  }

  static async setChatCommonConfig (chat_id: number, config: SetChatCommonConfigReq): AxiosPromise<{ chat: NewChatConfig }> {
    return PostApi.sendRequest('tg', 'setchatcommonconfig', { chat_id, ...config })
  }
  static async clearUsers(type: 'tg', body: any) {
    return PostApi.sendRequest(type, 'clearusers', body)
  }

  static async transferLicense(type: 'tg', { chat_id, target_id, license_type }: Record<string, any>) {
    if (license_type === 0 || license_type) {
      return PostApi.sendRequest(type, "transferlicense", { chat_id, target_id, license_type });
    }

    return PostApi.sendRequest(type, "transferlicense", { chat_id, target_id });
  }

  // backups
  static async getChatBackups(type: 'tg', { chat_id }: Record<string, any>) {
    return PostApi.sendRequest(type, "getchatbackups", { chat_id });
  }

  static async restoreBackup(type: 'tg', { chat_id, code }: Record<string, any>) {
    return PostApi.sendRequest(type, "restorebackup", { chat_id, code });
  }

  // channel bind
  static async bindChatChannel(type: 'tg', { chat_id, channel }: Record<string, any>) {
    return PostApi.sendRequest(type, "bindchatchannel", {
      chat_id,
      channel,
    });
  }

  static async bindChannelByCode(reqType: 'tg', { chat_id, type }: Record<string, any>) {
    return PostApi.sendRequest(reqType, 'bindchannelbycode', {
      chat_id,
      type
    })
  }

  static async unbindChatChannel(type: 'tg', { chat_id }: Record<string, any>) {
    return PostApi.sendRequest(type, "unbindchatchannel", { chat_id });
  }

  static async stateChannelByCode(type: 'tg', { code }: Record<string, any>) {
    return PostApi.sendRequest(type, "statechannelbycode", { code });
  }

  // branding
  static getBrandConfig(type: 'tg', { chat_id }: Record<string, any>) {
    return PostApi.sendRequest(type, "getbrandconfig", { chat_id });
  }

  static resetChatBrand(type: 'tg', { chat_id }: Record<string, any>) {
    return PostApi.sendRequest(type, "resetchatbrand", { chat_id });
  }

  static setBrandConfig(type: 'tg', { config, chat_id }: Record<string, any>) {
    return PostApi.sendRequest(type, "setbrandconfig", {
      chat_id,
      config,
    });
  }

  static setChatBrand(type: 'tg', { chat_id, token }: Record<string, any>) {
    return PostApi.sendRequest(type, "setchatbrand", { chat_id, token });
  }

  static getJournal(type: 'tg', { chat_id, offset, limit }: Record<string, any>) {
    return PostApi.sendRequest(type, "getjournal", {
      chat_id,
      offset,
      limit,
    });
  }

  // import/export
  static exportChatConfig(type: 'tg', { chat_id, triggers }: Record<string, any>) {
    let query = `${ PostApi.getRequestUrl(type, "exportchatconfig") }?chat_id=${ chat_id }`

    if (triggers) {
      query += `&triggers=${ triggers.join(',') }`
    }

    window.open(query, "_blank");
  }

  static exportChatTriggers(type: 'tg', { triggers, chat_id }: Record<string, any>) {
    window.open(`${ PostApi.getRequestUrl(type, "exportchattrigger") }?chat_id=${ chat_id }&triggers=${ triggers.join(',') }`, "_blank");
  }

  static importChatConfigUrl(type: 'tg', formData: FormData) {
    return PostApi.sendRequest(type, "importchatconfig", formData);
  }
}

