import { IAnchorData } from "@/includes/logic/Anchors/anchors";

import MessageEditorWithMediaData from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export default class AnchorData implements IAnchorData {
  trigger!: string
  triggers!: Array<string>

  @Type(() => MessageEditorWithMediaData)
  reaction!: Array<MessageEditorWithMediaData>

  delete_previous!: boolean
  enabled!: boolean
  strict!: boolean
  send_as_reply!: boolean
  remove_request!: boolean
}
