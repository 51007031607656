import {
  InviteLinkStatReq,
  InviteLinksWithStat, InviteLinkToCreate, InviteLinkToEdit,
  NewInviteLink
} from "@/includes/logic/InviteLinks/types";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

async function reqInviteLinks(chat_id: number): AxiosPromise<{ links: Array<NewInviteLink>}>  {
  return PostApi.sendRequest('tg', 'getinvitelinks', { chat_id })
}

async function reqInviteLinksStat(chat_id: number, body: InviteLinkStatReq): AxiosPromise<{ links: InviteLinksWithStat}> {
  return PostApi.sendRequest('tg', 'getinvitelinksstat', { chat_id, ...body })
}

async function reqDeleteInviteLink(chat_id: number, code: NewInviteLink['code']): AxiosPromise<void> {
  return PostApi.sendRequest('tg', 'deleteinvitelink', { chat_id, code })
}

async function reqEditInviteLink(chat_id: number, link: InviteLinkToEdit): AxiosPromise<{ link: NewInviteLink }> {
  return PostApi.sendRequest('tg', 'setinvitelink', { chat_id, ...link })
}

async function reqCreateInviteLink(chat_id: number, link: InviteLinkToCreate): AxiosPromise<{ link: NewInviteLink }> {
  return PostApi.sendRequest('tg', 'createinvitelink', { chat_id, ...link })
}

export {
  reqInviteLinks,
  reqInviteLinksStat,
  reqDeleteInviteLink,
  reqEditInviteLink,
  reqCreateInviteLink
}
