import {
  ActionsType, ActionsWrapperType,
  NewTriggerCondition, OldTriggerCondition,
  RawTriggerData, TriggerActionGroup,
  TriggerCondition, TriggerData
} from "@/components/TriggerSetup/logic/types/types";
import { getTriggerConditions } from "@/components/TriggerSetup/conditions";
import { actionsWrappers, getTriggerActions } from "@/components/TriggerSetup/actions";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { getActionGroupTemplate, getTriggerTemplate } from "./triggerTemplates";

import { IterableItemFactory, IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { cloneDeep } from 'lodash'
import * as Sentry from "@sentry/browser";

export class TriggerFactory {

  triggerActions = getTriggerActions()

  iterableItemFactory: IterableItemFactory = new IterableItemFactory()

  getTriggerTemplate(): RawTriggerData {
    return getTriggerTemplate();
  }

  get actionGroupTemplate(): TriggerActionGroup {
    return getActionGroupTemplate()
  }

  createConditionsGroup(value?: Array<IterableListItem<EntityData>>): IterableListItem<Array<IterableListItem<EntityData>>> {
    return this.iterableItemFactory.create<Array<IterableListItem<EntityData>>>(value || [])
  }

  createCondition(value: TriggerCondition): IterableListItem<EntityData> {
    const valueCopy = cloneDeep(value)

    const conditionVersion = valueCopy.version
    const conditionsDict = getTriggerConditions(conditionVersion)
    const conditionType: OldTriggerCondition['type'] | NewTriggerCondition['condition_type'] = conditionVersion === 'v1'
        ? valueCopy.type
        : valueCopy.condition_type

    let condition: EntityData | undefined = cloneDeep(conditionsDict[conditionType])

    if (!condition) {
      Sentry.captureException(new Error(`Unknown condition type, got: ${ conditionType }, version: ${ conditionVersion }`), {
        extra: {
          rawCondition: value
        }
      });
    }

    condition.structure = valueCopy

    return this.iterableItemFactory.create(condition)
  }

  createActionGroup(value?: any): any {
    return this.iterableItemFactory.create<any>({ ...this.actionGroupTemplate, ...value } || this.actionGroupTemplate)
  }

  createAction(value: any): IterableListItem<any> {
    const valueCopy = cloneDeep(value)

    if (value.structure === undefined) {
      let action: EntityData;

      if (actionsWrappers.WarnActionsWrapper.includes(valueCopy.type)) {
        action = cloneDeep(this.triggerActions[ActionsWrapperType.WarnActionsWrapper]);

        action.structure.sendWarnMessage = valueCopy.type === ActionsType.WarnAction
        action.structure.currentAction = valueCopy
      } else if (actionsWrappers.AchievementsActionsWrapper.includes(valueCopy.type)) {
        action = cloneDeep(this.triggerActions[ActionsWrapperType.AchievementsActionsWrapper]);
        action.structure.currentAction = valueCopy
      } else {
        action = cloneDeep(this.triggerActions[value.type]);
        action.structure = value
      }

      return this.iterableItemFactory.create(action)
    }

    return this.iterableItemFactory.create(valueCopy)
  }

  processRawTriggerDataForFrontend(rawTriggerData: RawTriggerData): TriggerData {
    const rawTriggerDataCopy: RawTriggerData = cloneDeep(rawTriggerData)

    const conditions: Array<IterableListItem<Array<IterableListItem<EntityData>>>> =
        rawTriggerDataCopy.conditions
            .map((conditionGroup: Array<TriggerCondition>) =>
                (this.createConditionsGroup(conditionGroup.map((condition: TriggerCondition) => this.createCondition(condition)))))

    const actions: { [key: string]: any } = {}
    const actionsKeys: Array<string> = [ 'actions', 'conditionSuccessActions', 'conditionFailActions', 'limitActions', 'globalLimitActions', 'warnActions', 'formActions' ]

    actionsKeys.forEach((key: string) => {
      if ((rawTriggerDataCopy as { [key: string]: any })[key]) {
        actions[key] = ((rawTriggerDataCopy as { [key: string]: any })[key] as Array<any>)
            .map((actionGroup: any) => {
              actionGroup.actions = actionGroup.actions.map((action: any) => this.createAction(action))
              return this.createActionGroup(actionGroup)
            })
      } else {
        actions[key] = []
      }
    })

    return { ...rawTriggerDataCopy, conditions, ...actions } as any as TriggerData
  }

  triggerDataToRawTriggerData(triggerData: TriggerData): RawTriggerData {
    const triggerDataCopy: TriggerData = cloneDeep(triggerData);
    const getValues = (item: Array<IterableListItem<any>>, type?: string): any => {

      if (type === 'conditions') {
        return this.processConditions(item)
      } else {
        const listWithValuesOnly = item.map((child) => {
          if (child.value.actions) {
            return child.value
          }
        })

        if (listWithValuesOnly.length) {
          return listWithValuesOnly.map((subChild: any) => {
            if (subChild && subChild.actions) {

              subChild.actions = subChild.actions.map((action: any) => {
                if ([ ActionsWrapperType.WarnActionsWrapper, ActionsWrapperType.AchievementsActionsWrapper ].includes(action.value.structure.type)) {
                  return action.value.structure.currentAction
                }

                return action.value.structure
              })

              return subChild
            }
          })
        }
      }
    }

    return {
      ...triggerDataCopy,
      actions: getValues(triggerDataCopy.actions, 'actions'),
      conditions: getValues(triggerDataCopy.conditions, 'conditions'),
      conditionSuccessActions: getValues(triggerDataCopy.conditionSuccessActions, 'conditionSuccessActions'),
      conditionFailActions: getValues(triggerDataCopy.conditionFailActions, 'conditionFailActions'),
      limitActions: getValues(triggerDataCopy.limitActions, 'limitActions'),
      globalLimitActions: getValues(triggerDataCopy.globalLimitActions, 'globalLimitActions'),
      warnActions: getValues(triggerDataCopy.warnActions, 'warnActions'),
      formActions: getValues(triggerDataCopy.formActions, 'formActions'),
    } as unknown as RawTriggerData
  }

  processConditions(item: Array<IterableListItem<Array<IterableListItem<EntityData>>>>) {
    return item.map((child) => child.value.map((subChild) => subChild.value.structure))
  }

  createNewTrigger({ chatId }: { chatId: number }): TriggerData {
    const triggerData: RawTriggerData = cloneDeep(this.getTriggerTemplate())
    triggerData.shared = true
    triggerData.chat_id = chatId
    return this.processRawTriggerDataForFrontend(triggerData)
  }

  createNewTriggerFromExists(triggerData: RawTriggerData): TriggerData {
    triggerData = cloneDeep(triggerData)
    delete triggerData.id;
    delete triggerData.owner;
    delete triggerData.owner_id;

    return this.processRawTriggerDataForFrontend({ ...this.getTriggerTemplate(), ...triggerData })
  }
}
