










































































import BotApi from "@/includes/Api/Bot.api";
import { Style } from "@/views/posts-planner/posts.types";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import { errorNotification } from '@/includes/NotificationService'
import { BRAND_TAG } from '@/includes/constants'

import EmptyData from "piramis-base-components/src/components/EmptyData/EmptyData.vue";
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import Vue from "vue";
import Component from "vue-class-component";

@Component({
  'components': {
    PageTitle,
    NotAvailableOptionsOverlay,
    EmptyData
  }
})
export default class BoardStylesList extends Vue {

  BRAND_TAG = BRAND_TAG

  stylesList: Record<string, Style> = {}

  isPopupOpen = false

  isStylesLoading = false

  get boardTokenCode() {
    return this.$store.state.postsPlanner.currentBoard.board.token_code
  }

  toStyleCreatePage(): void {
    this.$router.push({
      name: 'style',
      params: {
        actionType: 'new'
      },
    })
  }

  toStyleEditPage(index: string): void {
    this.$router.push({
      name: 'style',
      params: {
        actionType: 'edit',
      },
      query: {
        styleId: index
      }
    })
  }

  removeStyleWarn(index: any): void {
    this.$confirm({
      title: this.$t('remove_style_warn_title').toString(),
      content: this.$t('remove_style_warn_text').toString(),
      okText: this.$t('pi_accept').toString(),
      okType: 'danger',
      cancelText: this.$t('pi_reject').toString(),
      centered: true,
      onOk: () => {
        this.removeStyle(index)
      }
    })

  }

  removeStyle(index: any): void {
    this.isStylesLoading = true
    this.$delete(this.stylesList, index)

    BotApi.setBoardConfig('tg', {
      board: this.$store.getters.currentBoardKey,
      config: { styles: this.stylesList }
    })
      .catch(errorNotification)
      .finally(() => this.isStylesLoading = false)
  }

  mounted(): void {
    if (this.boardTokenCode !== 'default') {
      this.fetchConfig(this.$store.getters.currentBoardKey)
    }
  }

  fetchConfig(board: string): void {
    this.isStylesLoading = true

    BotApi.getBoardConfig('tg', { board })
      .then(({ data }) => {
        this.stylesList = data.config.styles
      })
      .catch(errorNotification)
      .finally(() => {
        this.isStylesLoading = false
      })
  }

}
