<template>
  <a-spin
    class="chat-backups relative"
    :spinning="loading"
  >
    <config-field
      has-anchor
      :title="$t('chat_backups')"
      :mini-help-message="$t('chat_backups_short_help_message')"
    />

    <a-table
      class="mt-2"
      :data-source="backups"
      :row-key="(record) => record.code"
      :pagination="{
        pageSize: 3
      }"
    >
      <a-table-column
        key="index"
        title="№"
      >
        <template slot-scope="text, record, index">
          {{index + 1}}
        </template>
      </a-table-column>
      <a-table-column
        key="date"
        :title="$t('chat_backups_info')"
      >
        <template slot-scope="text, record, index">
          <div class="flex flex-col">
            <span>
              {{`${$t('chat_backups_date', { date: record.date })}`}}
            </span>
            <div
              v-if="record.user"
              class="flex items-center"
            >
              <a-avatar
                :src="record.user.avatar"
                class="mr-2"
              />
              <span class="font-medium">
                {{ record.user.name ? record.user.name : record.user.login }}
              </span>
            </div>
          </div>
        </template>

      </a-table-column>
      <a-table-column key="actions">
        <template slot-scope="text, record">
          <a-button
            icon-pack="feather"
            icon="undo"
            type="danger"
            :disabled="disabled"
            @click="restoreBackup(record.code)"
          />
        </template>
      </a-table-column>
    </a-table>
  </a-spin>
</template>

<script>
import ChatApi from "@/includes/Api/Chat.api";
import { errorNotification, successNotification } from "@/includes/NotificationService";

import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";

export default {
  name: "ChatBackups",
  components: {
    ConfigField
  },
  props: [ 'disabled' ],
  data() {
    return {
      backups: [],
      loading: false,
    }
  },
  methods: {
    restoreBackup(code) {
      this.$confirm({
        title: this.$t("restore_backup_dialog_title").toString(),
        content: this.$t("restore_backup_dialog_text").toString(),
        okText: this.$t("pi_accept").toString() ,
        cancelText: this.$t("pi_reject").toString() ,
        onOk: () => {
          this.loading = true

          ChatApi.restoreBackup('tg', { chat_id: this.$store.state.chatState.chat.chat_id, code })
            .then(() => {
              this.$store.dispatch('requestChatConfig', { id: this.$store.state.chatState.chat.chat_id })
                .then(this.getChatBackups)
                .then(() => successNotification())
            })
            .catch(errorNotification)
            .finally(() => this.loading = false)
        }
      })
    },
    getChatBackups() {
      this.loading = true
      ChatApi.getChatBackups('tg', { chat_id: this.$store.state.chatState.chat.chat_id })
        .then(({ data }) => {
          this.backups = data.items.map((item, index) => {
            return {
              // 2020-10-26T09:09:59
              date: `${
                new Date(item.date).toLocaleDateString( this.$i18n.locale, {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric'
                })
              }`,
              code: item.code,
              user: item.user_id ? {
                user_id: item.user_id,
                avatar: item.avatar,
                login: item.user_login,
                name: item.user_name
              } : null
              // index: index + 1
            }
          })
        })
        .catch(errorNotification)
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    this.getChatBackups()
  }
}
</script>

<style lang="scss">
.chat-backups {
  .vs-con-tbody.vs-table--tbody {
    border: none
  }
}
</style>
