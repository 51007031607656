import { ChatConfig } from "@/includes/types/Chat/types";
import { UserReputation } from "@/views/chat/statistics/logic/usersStatTypes";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

export default class StatisticsApi {
  static async getChatStat(type: string, { chat_id, from, to }: any): AxiosPromise<ChatStatistics> {
    return PostApi.sendRequest(type, "getchatstat", {
      chat_id,
      from,
      to,
    });
  }

  static async setReputation(type: string, { chat_id, user_id, value }: { chat_id: number, user_id: number, value: number }): AxiosPromise<
      Pick<ChatConfig, 'stat_access_key'> & Pick<UserReputation, 'karma' | 'anti_karma' | 'action_point' | 'id'>
  > {
    return PostApi.sendRequest(type, "setreputation", { chat_id, user_id, value });
  }
}

//todo -- ubrat` eto otsuda potom
export type MessageCountByDayRecord = {
    date: string
    avg_message_toxicity: number
    avg_messaget_severe_toxicity: number
    avg_message_profanity: number
    avg_days_with_message_count: number
    uniq_threads_count: number
    users_converted_in_first_day: number
    users_converted_after_first_day: number
    young_users_count: number
    other_users_count: number
    regular_users: number
    users_count: number
    users_in_comments_count: number
    users_with_reply_count: number
    users_with_edited_message_count: number
    users_with_links_count: number
    users_with_mentions_count: number
    message_count: number
    comments_count: number
    reply_message_count: number
    edited_message_count: number
}
export type MessageCountByHourRecord = {
  time: string
  message_count: number
  users_count: number
  threads_count: number
  message_toxicity: number
}

export type StatisticsEnterRecord = {
    date:string,
    enter_total: number
    enter_first: number
    enter_second: number
    invited_total: number
    leave_total: number
    leave_first: number
    leave_second: number
    kick: number
    active_period: number
    inactive_period: number
}

export type StatisticsCountAndOnlineRecord = {
  date: string
  online: number
  count: number
}

export type StatisticsRetentionRecord = {
    date: string
    days_with_message_count_1: number
    days_with_message_count_2: number
    days_with_message_count_3: number
    days_with_message_count_4: number
    days_with_message_count_5: number
    days_with_message_count_6: number
    days_with_message_count_7: number
    days_with_message_count_8: number
    days_with_message_count_9: number
    days_with_message_count_10: number
}

export type StatisticsMessageEngagementRecord = {
    date: string
    week_engagement_rate_by_users: number
    week_engagement_rate_by_messages: number
    week_engagement_rate_by_comments: number
    month_engagement_rate_by_users: number
    month_engagement_rate_by_messages: number
    month_engagement_rate_by_comments: number
    week_online_engagement_rate_by_users: number
    week_online_engagement_rate_by_messages: number
    week_online_engagement_rate_by_comments: number
    month_online_engagement_rate_by_users: number
    month_online_engagement_rate_by_messages: number
    month_online_engagement_rate_by_comments: number
}

export type ChatStatistics = {
  dau: number
  wau: number
  mau: number
  dam: number
  wam: number
  mam: number
  dac: number
  wac: number
  mac: number
  message_count_by_day: Array<MessageCountByDayRecord>
  message_count_by_hour: Array<MessageCountByHourRecord>
  enter: Array<StatisticsEnterRecord>
  count_and_online: Array<StatisticsCountAndOnlineRecord>
  message_engagement: Array<StatisticsMessageEngagementRecord>
  retention: Array<StatisticsRetentionRecord>
}
