import { CommunityDashboardUser, DashboardExtended, SummaryStatRecord } from "@/includes/types/DashboardExtended/types";
import DashboardErrors from "@/components/CommunityDashboard/DashboardErrors.vue";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

export default class DashboardApi {

  static async getDashBoardStatus(chat_id: number): AxiosPromise<{ errors: Array<DashboardErrors> }> {
    return PostApi.sendRequest('tg', 'getdashboardstatus', { chat_id })
  }

  static async getDashBoardSummaryStatistics(chat_id: number): AxiosPromise<{ summary_statistics: Array<SummaryStatRecord>}> {
    return PostApi.sendRequest('tg', 'getdashboardsummarystatistics', { chat_id })
  }

  static async getDashBoardCommunityStatistics(chat_id: number): AxiosPromise<DashboardExtended> {
    return PostApi.sendRequest('tg', 'getdashboardcommunitystatistics', { chat_id })
  }

  static async getUsersCommunityStat(chat_id: number, user_ids: Array<number>): AxiosPromise<{ users: Array<CommunityDashboardUser> }> {
    return PostApi.sendRequest('tg', 'getuserscommunitystat', { chat_id, user_ids })
  }
}
