

































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import SummarizeMessageHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/SummarizeMessageHandlerModule/SummarizeMessageHandlerModuleBuilder";
import Placeholders from "@/mixins/placeholders/placeholders";
import ModulesRoutesHelperMixin from "@/includes/logic/Modules/mixins/ModulesRoutesHelperMixin";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import TimePickerInput from 'piramis-base-components/src/components/Pi/fields/TimePickerInput/TimePickerInput.vue'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue';
import { MediaEditorMode } from "piramis-base-components/src/components/NewMessageEditors/types";
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue';

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      MediaEditorMode
    }
  },
  components: {
    SwitchInput,
    TimePickerInput,
    MultiMessageEditorWithMediaInput,
    NestedContent
  },
})
export default class SummarizeMessageHandlerModuleView extends Mixins(ModuleBindings, InputSetups, Placeholders, ModulesRoutesHelperMixin) {
  @VModel() module!: SummarizeMessageHandlerModuleBuilder

  created() {
    if (!this.module.isVisible()) {
      this.gotoModulesCatalog()
    }
  }
}
