var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"permitted-word-filter-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.pageHelpMessageOrDescription(_vm.module.model),"tags":_vm.module.tariffTags()},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{attrs:{"title":_vm.backButtonTitle},on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('filter-setting-layout',{attrs:{"filter-model":_vm.module.model},scopedSlots:_vm._u([{key:"tab-main",fn:function(){return [_c('a-card',{attrs:{"title":_vm.$t('field_special_settings_title')}},[_c('typed-chips-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'permitted_words',
              'hasAnchor': true,
              'possibleChipTypeInfos': _vm.BASE_SUPER_LIST_TYPES,
              'settingWrapper': {
                hasFieldButton: true,
                disabledValue: [ ],
                customiseValue: [ ],
              },
              'disabled': _vm.disabled
            }
          }}})],1),_c('filter-journal',{attrs:{"warn-field":_vm.module.model.journal_moderation_warn,"ban-field":_vm.module.model.journal_moderation_ban,"disabled":_vm.disabled},on:{"update:warnField":function($event){return _vm.$set(_vm.module.model, "journal_moderation_warn", $event)},"update:warn-field":function($event){return _vm.$set(_vm.module.model, "journal_moderation_warn", $event)},"update:banField":function($event){return _vm.$set(_vm.module.model, "journal_moderation_ban", $event)},"update:ban-field":function($event){return _vm.$set(_vm.module.model, "journal_moderation_ban", $event)}}}),_c('filter-extra-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true},{key:"tab-regular",fn:function(){return [_c('filter-common-settings',{attrs:{"fields-prefix":_vm.module.model.snakeCaseFilterType,"disabled":_vm.disabled,"custom-punish-builders":_vm.module.customPunishBuilders},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true},{key:"tab-new",fn:function(){return [_c('filter-new-users-settings',{attrs:{"fields-prefix":_vm.module.model.snakeCaseFilterType,"disabled":_vm.disabled,"custom-punish-builders":_vm.module.customPunishBuilders},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true},{key:"tab-edit",fn:function(){return [_c('filter-remove-edited-setting',{attrs:{"disabled":_vm.disabled,"custom-punish-builders":_vm.module.customPunishBuilders},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }