








































import PunishMessage from "@/components/Modules/punish-actions/components/PunishMessage.vue";
import { InputSetups } from "@/mixins/input-setups";
import { KickAction } from "@/includes/logic/Modules/models/PunishActions";
import { ClassProperties } from "@/includes/utils/types";

import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';

@Component({
  data() {
    return {
      StepUnit
    }
  },
  components: {
    PunishMessage,
    SwitchInput,
    TimeUnitInput,
    NestedContent
  }
})
export default class KickActionView extends Mixins(InputSetups) {
  @VModel() model!: KickAction

  @Prop() defaultModel!: Partial<ClassProperties<KickAction>>

}
