import { BaseChatInfo } from "@/includes/types/Chat/types";
import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

export default class TriggersApi {
  static async getChatTriggers(chat_id: BaseChatInfo['chat_id']): AxiosPromise<{triggers: Array<RawTriggerData>}> {
    return PostApi.sendRequest("tg", 'getchattriggers', { chat_id })
  }

  static async setTrigger(trigger: RawTriggerData): AxiosPromise<RawTriggerData> {
    return PostApi.sendRequest("tg", 'settrigger', trigger)
  }

  static async deleteTrigger(id: Required<Pick<RawTriggerData, 'id'>>['id']): AxiosPromise<void> {
    return PostApi.sendRequest("tg", 'deletetrigger', { id })
  }

  static async getTrigger(id: Required<Pick<RawTriggerData, 'id'>>['id']): AxiosPromise<{ trigger: RawTriggerData }> {
    return PostApi.sendRequest("tg", 'gettrigger', { id })
  }
}
