import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";
import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import CommandLimitHandlerModule
  from "@/includes/logic/Modules/models/modules/CommandLimitHandlerModule/CommandLimitHandlerModule";
import {
  isLimitAllPermission,
  isWhitelistSelected,
  rawLimitModal,
} from "@/includes/logic/CommandLimits/CommandLimitsLogic";
import { CommandLimits, CommandsLimitLogicState, FrontendCommandLimit, FrontendCommandLimits } from "@/includes/logic/CommandLimits/types";

import { IterableItemFactory } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { cloneDeep } from "lodash";

export default class CommandLimitHandlerModuleBuilder extends ModuleBuilder {
  model!: CommandLimitHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/CommandLimitHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ModerationCommands

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'code_off',
  }

  itemFactory = new IterableItemFactory()

  state: CommandsLimitLogicState = {
    limits: [],
    selectedLimit: null
  }

  setFrontendLimits() {
    this.state.limits = this.model.params.command_limits.map(l => this.itemFactory.create(l))
  }

  frontendLimitsToRaw(limits: FrontendCommandLimits) {
    return cloneDeep(limits).map(l => l.value)
  }

  setModelLimits(limits: CommandLimits) {
    this.model.params.command_limits = [ ...limits ]
  }

  isLimitAllPermission(limit: FrontendCommandLimit) {
    return isLimitAllPermission(limit)
  }

  isWhitelistSelected(limit: FrontendCommandLimit) {
    return isWhitelistSelected(limit)
  }

  newFrontendLimitModel(): FrontendCommandLimit {
    return this.itemFactory.create(cloneDeep(rawLimitModal))
  }

  addLocalLimit () {
    if (this.state.selectedLimit) {
      this.state.limits.push(this.state.selectedLimit)
    }
  }

  updateLocalLimit() {
    const newLimit = this.state.selectedLimit;

    if (newLimit) {
      const limitIndex = this.state.limits.findIndex(l => l.guid === newLimit.guid)

      this.state.limits.splice(limitIndex, 1, newLimit)
    }
  }

  deleteLimit = (guid: string) => {
    this.state.limits = this.state.limits.filter(l => l.guid !== guid)
  }
}

