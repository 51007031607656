import { RawTriggerData, TriggerActionGroup } from "@/components/TriggerSetup/logic/types/types";

export function getTriggerTemplate(): RawTriggerData {
  return {
    type: "trigger",
    name: "",
    chat_id: null,
    actionExecuteType: "Random",
    chance: 100,
    delay: 0,
    limit: 0,
    limitInterval: 0,
    globalLimit: 0,
    globalLimitInterval: 0,
    warnLimit: 0,
    warnLimitInterval: 0,
    conditionCheckInterval: 0,
    processEditedMessages: false,
    processEditedMessageOnes: true,
    continueProcessAfterSuccessEdit: false,
    roundLimitInterval: false,
    roundGlobalLimitInterval: false,
    roundWarnLimitInterval: false,
    transparent: false,
    shared: false,
    multiplyForm: false,
    processAlbumsLikeFirstPhoto: true,
    form: "",
    conditions: [ [] ],
    actions: [ getActionGroupTemplate() ],
    conditionSuccessActions: [],
    conditionFailActions: [],
    limitActions: [],
    globalLimitActions: [],
    warnActions: [],
    formActions: []
  };
}

export function getActionGroupTemplate(): TriggerActionGroup {
  return {
    from: 0,
    to: 0,
    chance: 100,
    actions: [],
  }
}
