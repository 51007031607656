import { ModuleGroupEnum } from "@/includes/logic/Modules/types/types";
import i18n from "@/i18n/i18n";
import { AI_EXPERIMENTAL_TAG } from "@/includes/constants";

import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { snakeCase } from "lodash";

function getIcon(group: ModuleGroupEnum) {
  switch (group) {
    case ModuleGroupEnum.UsersModeration:
      return {
        name: 'how_to_reg',
        iconPack: 'material-icons',
        color: '#7fdc97'
      }
    case ModuleGroupEnum.MessagesModeration:
      return {
        name: 'announcement',
        iconPack: 'material-icons',
        color: '#d3b8f8'
      }
    case ModuleGroupEnum.AI:
      return {
        name: '🧠',
        color: AI_EXPERIMENTAL_TAG[0].color
      }
    case ModuleGroupEnum.ModerationCommands:
      return {
        name: 'keyboard_command_key',
        iconPack: 'material-icons',
        color: '#f18d91'
      }
    case ModuleGroupEnum.ActivityBoost:
      return {
        name: 'videogame_asset',
        iconPack: 'material-icons',
        color: '#ecc7a6'
      }
    case ModuleGroupEnum.Extra:
      return {
        name: 'more_horiz',
        iconPack: 'material-icons',
        color: '#6ddce7'
      }
  }
}

export const groupsSelectOptions: Array<SelectOptionData> = Object.values(ModuleGroupEnum).map(group => ({
      label: i18n.t(`module_group_${ snakeCase(group.toLowerCase()) }_title`).toString(),
      value: group,
      icon: getIcon(group)
    })
)

export const findGroupByValue = (group: ModuleGroupEnum) => {
  return groupsSelectOptions.find((g) => g.value === group)
}

export const stringIsModuleGroupEnum = (value: string): value is ModuleGroupEnum => {
  return Object.values(ModuleGroupEnum).includes(value as ModuleGroupEnum)
}
