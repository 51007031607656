
























































































import trimAndLowCaseFormat from '@/components/UsersStatByPeriod/logic/trimAndLowCaseFormat'

import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { debounce } from "lodash";

enum CurrentMode {
  Show,
  Hide
}

@Component({
  data() {
    return {
      CurrentMode
    }
  }
})
export default class TableSearch extends Vue {
  @Prop({ type: Function }) promiseList!: () => Promise<Array<string>>

  searchQuery = ''

  names:Array<string> = []

  currentMode: CurrentMode | null = null

  namesLoading = false

  @Watch('searchQuery')
  onSearchQueryChange = debounce((searchString:string) => {
    if (!this.names.length) {
      if (searchString.length) {
        this.onSearchUpdate(searchString)
      } else {
        this.resetSearch()
      }
    } else {
      if (this.currentMode === null) {
        this.resetSearch()
      }
    }
  }, 1000)

  @Emit('searchQuery:update')
  onSearchUpdate(query: string):string {
    return query
  }

  @Emit('resetSearch')
  resetSearch():null {
    this.currentMode = null

    return null
  }

  @Emit('showUsers')
  onShowUsersClick(tags:Array<string>):Array<string> {
    return tags
  }

  @Emit('hideUsers')
  onHideUsersClick(tags:Array<string>):Array<string> {
    return tags
  }

  handleShowUsersClick() {
    if (this.currentMode === CurrentMode.Show) {
      this.currentMode = null

      this.resetSearch()
    } else {
      this.currentMode = CurrentMode.Show

      this.onShowUsersClick(this.names)
    }
  }

  handleHideUsersClick() {
    if (this.currentMode === CurrentMode.Hide) {
      this.currentMode = null

      this.resetSearch()
    } else {
      this.currentMode = CurrentMode.Hide

      this.onHideUsersClick(this.names)
    }
  }

  onResetNames() {
    this.names = []

    this.resetSearch()
  }

  onTagClose(tag:string):void {
    this.names = this.names.filter(n => n !== tag)
    const modifiedNames = this.names.map(trimAndLowCaseFormat)

    if (!modifiedNames.length) {
      this.resetSearch()
    } else {
      this.updateByMode()
    }
  }

  updateByMode():void {
    if (this.currentMode === CurrentMode.Hide) {
      this.onHideUsersClick(this.names)
    }
    if (this.currentMode === CurrentMode.Show) {
      this.onShowUsersClick(this.names)
    }
  }

  addSearchQuery():void {
    const formattedQuery = trimAndLowCaseFormat(this.searchQuery)

    if (formattedQuery && !this.names.includes(formattedQuery)) {
      this.names.push(formattedQuery)
      this.updateByMode()
      this.searchQuery = ''
    }
  }

  created() {
    if (this.promiseList !== null) {

      this.namesLoading = true

      this.promiseList()
        .then((list) => {
          this.names = list
          this.currentMode = CurrentMode.Show
        })
        .finally(() => {
          this.namesLoading = false
        })
    }
  }
}
