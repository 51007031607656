var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"bind-channel",attrs:{"centered":"","title":_vm.bindTargetTitle,"footer":null},model:{value:(_vm.isBindModalOpen),callback:function ($$v) {_vm.isBindModalOpen=$$v},expression:"isBindModalOpen"}},[_c('pi-tabs',{staticClass:"flex justify-center mb-2",attrs:{"tabs":_vm.tabs,"current":_vm.activeMethod},on:{"update:current":function($event){_vm.activeMethod=$event}}}),(_vm.activeMethod === _vm.ActiveBindMethod.Self)?_c('div',{staticClass:"self-bind-method"},[_c('a-alert',{attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.selfAlertMessage)},slot:"message"})]),_c('text-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'bindTargetId',
          'validation': 'required',
          prefix: _vm.prefix
        }
      }},on:{"pressEnter":_vm.checkChannelValue}}),_c('div',{staticClass:"text-center"},[_c('a-button',{staticClass:"btn-success btn-wrap",attrs:{"disabled":!_vm.bindTargetId.trim()},on:{"click":_vm.checkChannelValue}},[_vm._v(" "+_vm._s(_vm.bindTargetTitle)+" ")])],1)],1):_vm._e(),(_vm.activeMethod === _vm.ActiveBindMethod.Code)?_c('div',[_c('a-alert',{attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.codeAlertMessage)},slot:"message"})]),_c('bind-channel-by-code',{staticClass:"code-bind-method mt-5",attrs:{"bind-code-type":'CustomChannel'},on:{"onChannelBind":_vm.handleOnChannelBind}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }