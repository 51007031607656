import ModuleConfig from "@/includes/logic/Modules/models/ModuleConfig";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";
import UsersCondition from "@/includes/logic/Modules/models/conditions/UsersCondition/UsersCondition";
import store from "@/store/store";
import GetUserInfoServiceImpl from "@/includes/logic/GetUserInfoServiceImpl";
import TopicsConditionBuilder from "@/includes/logic/Modules/models/conditions/TopicsCondition/TopicsConditionBuilder";
import ZoneConditionBuilder from "@/includes/logic/Modules/models/conditions/ZoneCondition/ZoneConditionBuilder";
import UsersConditionBuilder from "@/includes/logic/Modules/models/conditions/UsersCondition/UsersConditionBuilder";
import WorkTimeConditionBuilder
  from "@/includes/logic/Modules/models/conditions/WorkTimeCondition/WorkTimeConditionBuilder";
import ResolveFromRankConditionBuilder
  from "@/includes/logic/Modules/models/conditions/ResolveFromRankCondition/ResolveFromRankConditionBuilder";
import ResolveFromXpConditionBuilder
  from "@/includes/logic/Modules/models/conditions/ResolveFromXpCondition/ResolveFromXpConditionBuilder";
import ResolveFromRepConditionBuilder
  from "@/includes/logic/Modules/models/conditions/ResolveFromRepCondition/ResolveFromRepConditionBuilder";
import ResolveFromApConditionBuilder
  from "@/includes/logic/Modules/models/conditions/ResolveFromApCondition/ResolveFromApConditionBuilder";
import BoostsConditionBuilder from "@/includes/logic/Modules/models/conditions/BoostsCondition/BoostsConditionBuilder";
import UserTypeConditionBuilder from "@/includes/logic/Modules/models/conditions/UserType/UserTypeConditionBuilder";
import MessageContentTypeConditionBuilder
  from "@/includes/logic/Modules/models/conditions/MessageContentTypeCondition/MessageContentTypeConditionBuilder";

import { IterableItemFactory, IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { keyBy } from "lodash";

export default class ConditionsConfigService {

  iterableItemFactory = new IterableItemFactory()

  frontendConditions: Array<IterableListItem<Array<IterableListItem<ConfigCondition>>>> = []

  usersInfoService = new GetUserInfoServiceImpl(store.state.networksState.currentNetwork?.chats?.[0] ?? store.getters.chatId)

  constructor(conditions: ModuleConfig['conditions']) {
    this.frontendConditions = this.makeFrontendConditions(conditions)

    const ids = this.findUsersConditions(conditions)

    if (ids.length) {
      this.usersInfoService.getUsersInfos(ids)
    }
  }

  conditionBuilders = keyBy([
      TopicsConditionBuilder,
      ZoneConditionBuilder,
      UsersConditionBuilder,
      WorkTimeConditionBuilder,
      ResolveFromRankConditionBuilder,
      ResolveFromXpConditionBuilder,
      ResolveFromRepConditionBuilder,
      ResolveFromApConditionBuilder,
      BoostsConditionBuilder,
      UserTypeConditionBuilder,
      MessageContentTypeConditionBuilder,
    ], 'type')

  findUsersConditions(conditions: ModuleConfig['conditions']) {
    return conditions.reduce((acc: Array<number>, value) => {

      const cnd = value.filter(c => c instanceof UsersCondition) as Array<UsersCondition>

      if (cnd) {
        acc.push(...cnd.map(c => c.values.items).flat())
      }

      return acc
    }, [])
  }

  makeFrontendConditions(conditions: ModuleConfig['conditions']) {
    return conditions.map(c => this.iterableItemFactory.create(c.map(i => {
        return this.iterableItemFactory.create(i)
    })))
  }

  makeCleanConditions() {
    return this.frontendConditions.map(g => g.value.map(c => c.value))
  }

  removeCondition(guidToRemove: string, parentGuid: string) {
    const parent = this.frontendConditions.find(g => g.guid === parentGuid)

    if (parent) {
      parent.value = parent.value.filter(c => c.guid !== guidToRemove)

      if (!parent.value.length) {
        this.frontendConditions = this.frontendConditions.filter(g => g.guid !== parent.guid)
      }
    }
  }

  editCondition(newCondition: IterableListItem<ConfigCondition>, parentGuid: string) {
    const parent = this.frontendConditions.find(g => g.guid === parentGuid)

    if(parent) {
      const conditionIndex = parent.value.findIndex(c => c.guid === newCondition.guid)

      parent.value.splice(conditionIndex, 1, newCondition)
    }
  }

  addCondition(model: ConfigCondition, parentGuid?: string) {
    if (parentGuid && this.frontendConditions.length) {
      const parent = this.frontendConditions.find(g => g.guid === parentGuid)

      if (parent) {
        parent.value.push(this.iterableItemFactory.create(model))
      }
    } else {
      this.frontendConditions.push(this.iterableItemFactory.create([ this.iterableItemFactory.create(model) ]))
    }
  }
}
