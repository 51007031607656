var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"referral-module-view"},[_c('page-title',{attrs:{"tags":_vm.refSystemTag,"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.$te(_vm.module.model.pageHelpMessageKey) ? _vm.$t(_vm.module.model.pageHelpMessageKey) : null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),(!_vm.refSystemTag)?_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}}):_vm._e()]},proxy:true}])}),(!_vm.refSystemTag)?[_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('multi-message-editor-with-media-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'referral_private_message',
            'placeholders': _vm.REFERRAL_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'hasAnchor': true,
            'disabled': _vm.disabled
          },
        }}}),(_vm.isTriggerSet('referral_private_trigger'))?_c('div',{staticClass:"relative"},[_c('select-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'referral_private_trigger',
              'hasAnchor': true,
              'settingWrapper': {
                'customiseValue': _vm.activeTriggersOptions[0],
                'value': { value: '' },
              },
              'notFoundOptionText': this.$t('trigger_option_not_found'),
              'disabled': _vm.disabled
            }
          }}}),_c('tags',{staticClass:"absolute",staticStyle:{"top":"-7px"},attrs:{"tags":_vm.willBeRemovedTag}}),_c('select-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'referral_private_trigger_new',
              'settingWrapper': {
                'customiseValue': _vm.activeTriggersOptions[0],
                'value': { value: '' },
              },
              'notFoundOptionText': this.$t('trigger_option_not_found'),
              'disabled': _vm.disabled
            }
          }}})],1):_c('select-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'referral_private_trigger_new',
            'hasAnchor': true,
            'settingWrapper': {
              'customiseValue': _vm.activeTriggersOptions[0],
              'value': { value: '' },
            },
            'notFoundOptionText': this.$t('trigger_option_not_found'),
            'disabled': _vm.disabled
          }
        }}}),(_vm.isTriggerSet('referral_first_private_trigger'))?_c('div',{staticClass:"relative"},[_c('select-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'referral_first_private_trigger',
              'hasAnchor': true,
              'settingWrapper': {
                'customiseValue': _vm.activeTriggersOptions[0],
                'value': { value: '' },
              },
              'notFoundOptionText': this.$t('trigger_option_not_found'),
              'disabled': _vm.disabled
            }
          }}}),_c('tags',{staticClass:"absolute",staticStyle:{"top":"-7px"},attrs:{"tags":_vm.willBeRemovedTag}}),_c('select-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'referral_first_private_trigger_new',
              'hasAnchor': true,
              'settingWrapper': {
                'customiseValue': _vm.activeTriggersOptions[0],
                'value': { value: '' },
              },
              'notFoundOptionText': this.$t('trigger_option_not_found'),
              'disabled': _vm.disabled
            }
          }}})],1):_c('select-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'referral_first_private_trigger_new',
            'hasAnchor': true,
            'settingWrapper': {
              'customiseValue': _vm.activeTriggersOptions[0],
              'value': { value: '' },
            },
            'notFoundOptionText': this.$t('trigger_option_not_found'),
            'disabled': _vm.disabled
          }
        }}}),(_vm.isTriggerSet('referral_not_first_private_trigger'))?_c('div',{staticClass:"relative"},[_c('select-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'referral_not_first_private_trigger',
              'hasAnchor': true,
              'settingWrapper': {
                'customiseValue': _vm.activeTriggersOptions[0],
                'value': { value: '' },
              },
              'notFoundOptionText': this.$t('trigger_option_not_found'),
              'disabled': _vm.disabled
            }
          }}}),_c('tags',{staticClass:"absolute",staticStyle:{"top":"-7px"},attrs:{"tags":_vm.willBeRemovedTag}}),_c('select-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'referral_not_first_private_trigger_new',
              'hasAnchor': true,
              'settingWrapper': {
                'customiseValue': _vm.activeTriggersOptions[0],
                'value': { value: '' },
              },
              'notFoundOptionText': this.$t('trigger_option_not_found'),
              'disabled': _vm.disabled
            }
          }}})],1):_c('select-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'referral_not_first_private_trigger_new',
            'hasAnchor': true,
            'settingWrapper': {
              'customiseValue': _vm.activeTriggersOptions[0],
              'value': { value: '' },
            },
            'notFoundOptionText': this.$t('trigger_option_not_found'),
            'disabled': _vm.disabled
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'referral_private_check_user',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'referral_accept_invite',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}})],1)]:_c('not-available-options-overlay',{attrs:{"tags":_vm.refSystemTag}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }