import FormsApi from "@/includes/Api/Forms.api";
import { errorNotification } from "@/includes/NotificationService";

import { cloneDeep }       from "lodash";
import Vue                 from "vue";

export default {
	deleteForm({ state, commit }, guid) {
		return new Promise(resolve => {
			let formsCopy = cloneDeep(state.chatState.questionnaire.forms);

			formsCopy = formsCopy.filter(f => f.guid !== guid)

			commit("setObjectField", {
				object    : state.chatState.questionnaire,
				objectPath: "forms",
				value     : formsCopy,
			});
			resolve();
		});

	},
	getForm({ dispatch, state }, guid) {
		return new Promise((resolve, reject) => {
			dispatch("getForms")
				.then(() => {
					resolve(state.chatState.questionnaire.forms.map(item => {
						if(item.guid === guid) {
							resolve(item);
						}
					}).values());
					resolve();
				})
				.catch(err => {
					reject(err);
				});
		});
	},
	getForms({ state, commit }) {
		return new Promise((resolve, reject) => {
		  Vue.prototype.$baseTemplate.loader.open()
			FormsApi.getForms("tg", { chat_id: parseInt(state.chatState.chat.chat_id) })
				 .then(res => {
					 commit("setObjectField", {
						 object    : state.chatState.questionnaire,
						 objectPath: "forms",
						 value     : res.data.forms,
					 });
					 resolve();
				 })
				 .catch(err => {
				   errorNotification(err);
					 reject();
				 })
				 .finally(() => {
				   Vue.prototype.$baseTemplate.loader.close()
				 });
		});
	},
};
