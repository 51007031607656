import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

export default class FormsApi {
  static async testForm(type: 'tg', { fields, values }: Record<string, any>) {
    return PostApi.sendRequest(type, "testform", {
      fields,
      values,
    });
  }

  static async runForm(type: 'tg', { action, data, values }: Record<string, any>) {
    const form: any = {
      action,
      data,
    };

    if(values) {
      form.values = values;
    }

    return PostApi.sendRequest(type, "runform", form);
  }

  static async deleteForm(type: 'tg', { guid, chat_id }: Record<string, any>) {
    return PostApi.sendRequest(type, "deleteform", {
      guid,
      chat_id,
    });
  }

  static async getForms(type: 'tg', { chat_id }: Record<string, any>) {
    return PostApi.sendRequest(type, "getforms", { chat_id });
  }

  static async setForm(type: 'tg', { form }: Record<string, any>) {
    return PostApi.sendRequest(type, "setform", form);
  }
}
