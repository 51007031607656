





























import { InputSetups } from "@/mixins/input-setups";
import { PostToCreate } from "@/views/posts-planner/posts.types";

import PostPeriodConditions from 'piramis-base-components/src/shared/modules/posting/PostPeriod/PostPeriodConditions.vue'
import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";

import Component from "vue-class-component";
import { Mixins, VModel } from "vue-property-decorator";

@Component({
  components: {
    PostPeriodConditions,
    TimeUnitInput
  }
})
export default class PeriodSettings extends Mixins(InputSetups) {

  @VModel() model !: PostToCreate

  created() {
    if (this.model?.period === null) {
      this.model.period = {
        interval: 0,
        unit: 'MINUTES',
        conditions: []
      }
    }
  }
}
