


























































































































































































































































import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import {
  ActionsType,
  TriggerActionGroup,
  TriggerData
} from '@/components/TriggerSetup/logic/types/types'
import { TriggerFactory } from '@/components/TriggerSetup/logic/factories/trigger-factory'
import CreateAction from '@/components/TriggerSetup/components/CreateAction/CreateAction.vue'
import { TriggerInputsSetups } from '@/components/TriggerSetup/logic/mixins/TriggerSetupMixin'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { InputSetups } from '@/mixins/input-setups'
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";

import ConfigGroup from 'piramis-base-components/src/components/ConfigGroup/ConfigGroup.vue'
import BlockMovingControls from 'piramis-base-components/src/components/BlockMovingControls/BlockMovingControls.vue'
import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { MessageEditorWithMediaData } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { cloneDeep, snakeCase } from 'lodash'
import { Component, Mixins, Prop, Ref, VModel } from 'vue-property-decorator'
import { getTriggerActions } from "../../actions";

type CurrentActionInfo = {
  parentGuid: string | null
  action: IterableListItem<EntityData> | null
}

@Component({
  'components': {
    ActionChance,
    CreateAction,
    ConfigGroup,
    ConfigField,
    BlockMovingControls,
    List,
  },
  data() {
    return {
      ListDirection
    }
  },
  'methods': {
    snakeCase
  }
})
export default class ActionsGroups extends Mixins(UseFields, InputSetups, TriggerInputsSetups, TariffsTagsHelper) {
  @VModel({ 'type': Array, 'required': true }) model!: TriggerData['actions']

  @Prop({ type: String }) actionExecuteType!: 'Random' | 'All'

  @Prop({
    'type': Array,
    'required': false,
    'default': () => []
  }) selectedFormStruct!: Array<MessageEditorWithMediaData>

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop({ 'type': Boolean, 'default': false }) readonly showFromTo!: boolean

  @Prop({ 'type': Array, 'required': true }) filteredActions!: Array<Array<EntityData>>

  @Prop({ 'type': Boolean, 'required': false, 'default': false }) hasForm!: boolean

  @Ref('actionGroupFromTo') fromTo!: HTMLDivElement

  triggerFactory = new TriggerFactory()

  isActionExtraSettingModalOpen = false

  isActionGroupExtraSettingModalOpen = false

  currentActionInfo: CurrentActionInfo = this.initCurrentActionState()

  currentGroupInfo: any | null = null

  get currentActionForUserAction() {
    if (this.currentActionInfo) {
      const actions = [
        ActionsType.MuteAction,
        ActionsType.BanAction,
        ActionsType.KickAction,
        ActionsType.UnmuteAction,
        ActionsType.UnbanAction
      ]

      return actions.includes(this.currentActionInfo.action?.value.structure.type)
    }

    return false
  }

  isActionWrapper(action: any) {
    return action.value.structure.currentAction !== undefined
  }

  isExtraActionButtonActive(action: any) {
    if (this.isActionWrapper(action)) {
      return action.value.structure.currentAction.chance !== 100
    } else {
      return action.value.structure.chance !== 100
    }
  }

  isExtraGroupActionButtonActive(group: any) {
    return group.chance !== 100 || group.from !== 0 || group.to !== 0
  }

  afterModalClose() {
    this.currentActionInfo = this.initCurrentActionState()
  }

  initCurrentActionState(): CurrentActionInfo {
    return {
      parentGuid: null,
      action: null
    }
  }

  setTriggerGroupModel() {
    if (this.currentGroupInfo) {
      const { guid, value } = this.currentGroupInfo

      const group = this.model.find(g => g.guid === guid)

      if (group) {
        group.value.chance = value.chance
        group.value.from = value.from
        group.value.to = value.to
      }

    }

    this.isActionGroupExtraSettingModalOpen = false

  }

  setTriggerActionModel() {
    const { parentGuid, action } = this.currentActionInfo

    if (parentGuid && action) {
      const group = this.model.find(g => g.guid === parentGuid)

      if (group) {
        const groupAction = group.value.actions.find(a => a.guid === action.guid)

        if (groupAction) {
          if (this.isActionWrapper(groupAction) && this.isActionWrapper(action)) {
            groupAction.value.structure.currentAction.chance = action.value.structure.currentAction.chance
            groupAction.value.structure.currentAction.targetSource = action.value.structure.currentAction.targetSource
          } else {
            groupAction.value.structure.chance = action.value.structure.chance
            groupAction.value.structure.targetSource = action.value.structure.targetSource
          }

        }
      }
    }

    this.isActionExtraSettingModalOpen = false
  }

  prepareActionGroupExtraSettings(group: any) {
    this.currentGroupInfo = cloneDeep(group)
    this.isActionGroupExtraSettingModalOpen = true
  }

  prepareCurrentActionExtraSettings(parentGuid: CurrentActionInfo['parentGuid'], actionModel: CurrentActionInfo['action']) {
    this.currentActionInfo.parentGuid = parentGuid
    this.currentActionInfo.action = cloneDeep(actionModel)

    this.isActionExtraSettingModalOpen = true
  }

  addActionGroup(actionGroups: TriggerData['actions']): void {
    actionGroups.push(this.triggerFactory.createActionGroup())

    this.$emit('input', this.model)
  }

  createAction(actionGroup: IterableListItem<TriggerActionGroup>, key: EntityData['key']): void {
    const action = getTriggerActions()?.[key]

    if (action) {
      const actionType = key as ActionsType

      if ([ ActionsType.SendFormToPrivate, ActionsType.SendFormToChat, ActionsType.SendFormResultToTarget ].includes(actionType)) {
        if (this.selectedFormStruct) {
          action.structure.text = this.selectedFormStruct.slice()
        }
      }

      actionGroup.value.actions.push(this.triggerFactory.createAction(action))
      this.$emit('input', this.model)
    }
  }

  processOldActionTypes(action: EntityData) {
    if ([ ActionsType.ChangeReputationAction, ActionsType.ChangePointsAction ].includes(action.structure.type)) {
      switch (action.structure.type) {
        case ActionsType.ChangeReputationAction:
          action.structure.type = ActionsType.ChangeReputationIntervalAction
          break
        case ActionsType.ChangePointsAction:
          action.structure.type = ActionsType.ChangePointsIntervalAction
          break
      }
      if (action.structure.count !== undefined) {
        action.structure.from = Number(action.structure.count)
        action.structure.to = Number(action.structure.count)
        delete action.structure.count
      }
    }
  }

  created(): void {
    const actionGroups = this.model.slice()

    actionGroups.forEach((actionGroup) => {
      actionGroup.value.actions.forEach((action) => {
        if ([ ActionsType.ChangeReputationAction, ActionsType.ChangePointsAction ].includes(action.value.structure.type)) {
          this.processOldActionTypes(action.value)
        }
      })
    })
  }
}
