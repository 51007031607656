

















































import BotApi from "@/includes/Api/Bot.api";
import { errorNotification } from '@/includes/NotificationService'
import { Style, WatermarkPosition, WatermarkType } from "@/views/posts-planner/posts.types";
import PostStylesSetup from "@/views/posts-planner/components/PostStylesSetup.vue";
import { InputSetups } from "@/mixins/input-setups";

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { UseFields } from "piramis-base-components/src/components/Pi";

import { isEmpty } from 'lodash'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";

@Component({
  'components': {
    PageTitle,
    PostStylesSetup,
    ConfigField,
    ValidationProvider,
    ValidationObserver
  }
})
export default class StylePage extends Mixins(UseFields, InputSetups) {

  isObjectSet = false

  style = {
    name: ''
  }

  styleObject = {
    watermark_type: WatermarkType.None,
    watermark_position: WatermarkPosition.Center,
    watermark_data: null,
    disable_link_preview: false,
    disable_notify: false,
    pin: false,
    protect_content: true,
    prefix_message: [ {
      text: "",
      attachments: [],
      buttons: [],
      remove_after: 0,
      remove_previous: false,
      send_after: 0,
      pin: false,
      disable_link_preview: false,
      disable_notify: false,
    } ],
    postfix_message: [ {
      attachments: [],
      text: "",
      buttons: [],
      remove_after: 0,
      remove_previous: false,
      send_after: 0,
      pin: false,
      disable_link_preview: false,
      disable_notify: false,
    } ]
  } as Style

  styles: Record<any, Style> = {}

  isStyleLoading = false

  saveStyle(): void {
    this.isStyleLoading = true

    try {
      const styleObjectCopy = { ...this.styleObject }

      if (this.getActionType() === 'edit') {
        if (styleObjectCopy.watermark_type === 'File' && Array.isArray(styleObjectCopy.watermark_data)) {
          styleObjectCopy.watermark_data = styleObjectCopy.watermark_data.join()
        }

        if (this.getStyleIndex() !== this.style.name) {
          this.$delete(this.styles, this.getStyleIndex())
        }
      }
      this.$set(this.styles, this.style.name, styleObjectCopy)
      BotApi.setBoardConfig('tg', {
        board: this.boardTokenId,
        config: { styles: this.styles }
      })
        .then(() => {
          this.$router.push({ name: 'board_styles' })
        })
        .catch(errorNotification)
        .finally(() => this.isStyleLoading = false)
    } catch (e: unknown) {
      if (e instanceof Error) {
        this.isStyleLoading = false
        errorNotification(e.message)
      } else {
        console.error(e)
      }
    }
  }

  get boardTokenId() {
    return this.$store.getters.currentBoardKey
  }

  mounted(): void {
    this.isStyleLoading = true

    BotApi.getBoardConfig('tg', { board: this.boardTokenId })
      .then(({ data }) => {
        data.config.styles === null ? this.styles = {} : this.styles = data.config.styles
        if (!data.config.styles || !Object.keys(data.config.styles).length || this.getActionType() === 'new') {
          this.isObjectSet = true
        } else {
          try {
            const key = this.getStyleIndex()
            let style = this.styles[key]
            if (key === this.getStyleIndex()) {
              if (style.watermark_type === 'File') {
                style.watermark_data = style.watermark_data.split()
              }

              this.styleObject = { ...style }
              this.style.name = key
              this.isObjectSet = true
            }
          } catch (e) {
            errorNotification(e)
            this.$router.push({ name: 'board_styles' })
          }

        }
      })
      .catch(errorNotification)
      .finally(() => {
        this.isStyleLoading = false
      })
  }

  get disabled(): boolean {
    return this.styleObject.watermark_type !== 'None' && isEmpty(this.styleObject.watermark_data)
  }

  getStyleIndex(): any {
    return this.$route.query.styleId
  }

  getActionType(): string {
    return this.$route.params.actionType
  }

}
