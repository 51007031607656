

































































































































































































import PostsApi from "@/includes/Api/Posts.api";
import { InputSetups } from '@/mixins/input-setups'
import { CalendarPosts, CreatedPost, Target } from '@/views/posts-planner/posts.types'
import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'
import isMobile from '@/assets/utils/isMobile'
import AsyncPopup from '@/components/AsyncPopup.vue'
import { errorNotification, successNotification, warningNotification } from '@/includes/NotificationService'
import { postObjectFabric, setDatesPosts } from "@/includes/logic/posts/PostsHelper";

import DrawerWidthMixin from 'piramis-base-components/src/logic/helpers/DrawerWidthMixin'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { FileType } from 'piramis-base-components/src/components/File/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import TgPostPreview from "piramis-base-components/src/shared/modules/posting/PostPreview/TgPostPreview.vue";
import { DatePostsItem, PostPreviewData } from 'piramis-base-components/src/shared/modules/posting/PostPreview/types'
import DatePostsList from 'piramis-base-components/src/shared/modules/posting/PostPreview/components/DatePostsList.vue'
import {
  isPostPinned, isPostWithDisableNotify,
  isPostWithProtectContent,
  SplitTags
} from 'piramis-base-components/src/shared/modules/posting/PostPreview/includes/helpers'
import PostSettingsIcons from "piramis-base-components/src/shared/modules/posting/components/PostSettingsIcons.vue";
import { PostType } from "piramis-base-components/src/shared/modules/posting/types";

import { cloneDeep, groupBy } from 'lodash'
import moment from 'moment'
import Component from 'vue-class-component'
import { Mixins, Ref } from 'vue-property-decorator'
import FullCalendar, { CalendarOptions, EventApi, EventClickArg, EventInput, ViewApi } from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import enLocale from '@fullcalendar/core/locales/en-gb'
import ruLocale from '@fullcalendar/core/locales/ru'
import { Drawer } from "ant-design-vue";
import { Location } from "vue-router/types/router";

@Component({
  computed: {
    PostType() {
      return PostType
    }
  },
  'components': {
    PostSettingsIcons,
    TgPostPreview,
    FullCalendar,
    MobileLandscapeTriggerLayout,
    AsyncPopup,
    PageTitle,
    DatePostsList
  },
  methods: {
    isPostPinned,
    isPostWithProtectContent,
    isPostWithDisableNotify
  },
  data() {
    return {
      FileType
    }
  }
})
export default class PostsPlanner extends Mixins(InputSetups, UseFields, DrawerWidthMixin) {
  isActive = false

  historyComponentChange = false

  selectedNode: EventInput | EventApi | null = null

  newModel: any = {
    date: '',
    time: '',
    timezone: '',
    targets: '',
  }

  areButtonsSet = false

  eventsByDate: Array<DatePostsItem> = []

  isLoaded = false

  isMounted = false

  selectedDate = ''

  selectedTime = ''

  allEvents: Array<EventInput> = []

  @Ref('asyncPopup') asyncPopup!: AsyncPopup

  @Ref('drawer') drawer!: Drawer

  get currentBoard() {
    return this.$store.state.postsPlanner.currentBoard
  }

  get calendarApi() {
    return (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>).getApi()
  }

  get sidebarTitle(): string {
    if (this.selectedNode) {
      return this.$t('planner_page_post_preview').toString()
    }

    return this.$t('posts_planner_page_title').toString()
  }

  get getFullCalendarLocale() {
    return localStorage && localStorage.getItem('locale') === 'ru' ? ruLocale : enLocale
  }

  get selectOptionsChats(): Array<SelectOptionData> {
    if (this.currentBoard) {
      return this.currentBoard.targets.reduce((acc: Array<SelectOptionData>, target: Target) => {
        acc.push({
          'label': target.title,
          'value': target.id,
          'image': {
            'src': target.photo
          },
        })

        if (target.channel) {
          acc.push({
            'label': this.$t('groups_channel_option_label', [ target.channel.title, target.title ]).toString(),
            'value': target.channel.id,
            'image': {
              'src': target.channel.photo
            },
          })
        }

        return acc
      }, [])
    }

    return []
  }

  selectPost(post: DatePostsItem) {
    const findPost = this.allEvents.find(value => value.id === post.key)

    if (findPost) {
      this.selectedNode = findPost

      this.historyComponentChange = true
    }
  }

  scrollDrawer(y: number): void {
    if (this.drawer) {
      const bodyArr = document.getElementsByClassName('ant-drawer-body')

      if (bodyArr.length) {
        const body = bodyArr[0]

        body.scroll({ behavior: 'smooth', top: y })
      }
    }
  }

  getPostInfo(): Promise<PostPreviewData | void> | undefined {
    if (this.selectedNode) {
      return PostsApi.getPostInfo('tg', {
        board: this.$store.getters.currentBoardKey,
        key: this.selectedNode.id
      })
        .then(({ data }) => {
          const response = data.data

          return {
            post: {
              botTitle: this.currentBoard.title,
              state: this.selectedNode?.extendedProps?.state,
              message: response.post.post.message,
              run_time: this.selectedNode?.extendedProps?.run_time,
              start: this.selectedNode?.extendedProps?.postTime,
              schedule: response.post.schedule,
              description: '',
              split_type: data.split_type,
              targets: this.selectOptionsChats.filter(target => this.selectedNode?.extendedProps?.targets?.includes(target?.value))
            },
            postInfo: response.result.map((info: any) => {
              const target = (data.targets_info as Array<Target>).find(target => target.id === info.targetId || target?.channel?.id === info.targetId)

              return {
                error: info.error,
                time: moment(info.time).format('YYYY-MM-DD HH:mm:ss'),
                target: {
                  avatar: target?.photo,
                  title: target?.title
                }
              }
            })
              .sort((a: any, b: any) => moment(a.time).isAfter(moment(b.time)))
          }
        })
        .catch(errorNotification)
    }
  }

  onClose(): void {
    this.isActive = false
    this.selectedNode = null
    this.eventsByDate = []
  }

  removeEvent(data: EventApi): void {
    const isRepeatPost = data.extendedProps.run_time.length > 1

    this.$confirm({
      title: isRepeatPost ? this.$t('planner_popup_title_remove_repeat_post_warn').toString() : this.$t('planner_popup_title_remove_warn').toString(),
      content: isRepeatPost ? this.$t('planner_popup_remove_repeat_post_warn').toString() : this.$t('planner_popup_remove_warn').toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        const event = this.calendarApi.getEventById(data.id)

        if (event) {
          PostsApi.deletePost('tg', { board: this.$store.getters.currentBoardKey, key: event.id })
            .then(({ data }) => {
              event!.remove()
              this.selectedNode = null

              successNotification()

              this.calendarApi.refetchEvents()
            })
            .catch(errorNotification)
        }
        this.isActive = false
      }
    })
  }

  gotoPost(action: 'copy' | 'edit' | 'edit-published' | 'new', query?: Location['query']) {
    this.$router.push({
      name: 'post',
      params: {
        actionType: action
      },
      query
    })
  }

  getDateString(date: string): string {
    return moment(date).format('HH:mm').toString()
  }

  getText(message:CalendarPosts['post']['message'], limit?: number) {
    const dots = limit !== undefined ? '...' : ''

    if (message.type === 'Post' && message.variants.length) {
      return SplitTags(message.variants[0].text).slice(0, limit).trim() + dots
    } else if (message.type === 'Poll' || message.type === 'Quiz') {
      return message.text.slice(0, limit).trim() + dots
    } else {
      return ''
    }
  }

  get calendarOptions(): CalendarOptions {
    return {
      plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin ],
      headerToolbar: {
        left: 'prev,next,today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listMonth'
      },
      initialView: isMobile() ? 'listMonth' : 'dayGridMonth',
      editable: true,
      selectable: true,
      // в поле хранится дата публикации по факту, т.к. в виде календаря "неделя" идет округление времени, то посты отображаются некорректно, поэтому сортировка указана явно по полю
      eventOrder: 'postTime',
      selectMirror: true,
      weekends: true,
      eventClick: this.handleEventClick,
      navLinks: true,
      dateClick: (info) => this.handleDateClick(info.dateStr, info.view),
      locale: this.getFullCalendarLocale,
      locales: [ enLocale, ruLocale ],
      contentHeight: 1000,
      dayMaxEvents: true,
      views: {
        dayGridMonth: {
          dayMaxEvents: 2
        }
      },
      eventMaxStack: 3,
      lazyFetching: false,
      eventDidMount: (info) => {
        if (info.view.type === 'listMonth' && !this.areButtonsSet) {
          this.areButtonsSet = true
          this.setButtonsToDates()
        }

        this.$baseTemplate.loader.close()
      },
      datesSet: () => {
        this.areButtonsSet = false
      },
      moreLinkClick: (info) => this.handleDateClick(moment(info.date).format('YYYY-MM-DD'), info.view),
      events: (fetchInfo, successCallback) => {
        this.$nextTick(() => {
          this.$baseTemplate.loader.open()

          this.getDataFromApi(moment(fetchInfo.start).format('YYYY-MM-DD'), moment(fetchInfo.end).format('YYYY-MM-DD'), this.calendarApi.view.type)
            .then((returnEvents) => {
              if (returnEvents) {
                let events = [ ...returnEvents ]

                if (this.calendarApi.view.type === 'timeGridWeek') {
                  events = this.roundPostsTime(events)
                }

                successCallback(events)
              }

              this.$baseTemplate.loader.close()
            })
        })

      },
      eventDrop: (info) => {
        this.setDateTimeToNewModel(info.event.startStr, info.event.extendedProps?.schedule?.timezone)

        this.asyncPopup.open()
          .then(() => {
            PostsApi.movePost('tg', {
              board_key: this.$store.getters.currentBoardKey,
              post_key: info.event.extendedProps.key,
              time: moment(`${ this.newModel.date } ${ this.newModel.time }`).format('YYYY-MM-DD HH:mm:ss'),
              timezone: this.newModel.timezone
            })
              .then(() => this.calendarApi.refetchEvents())
              .catch(errorNotification)
          })
          .catch(() => info.revert())
      },
      eventAllow: (dropLocation, draggedEvent) => {
        if (draggedEvent?.extendedProps.run_time.length > 1) {
          if (moment(dropLocation.startStr).isBefore(moment()) ||
            moment(draggedEvent?.startStr).format('YYYY-MM-DD HH:mm') !== moment(draggedEvent?.extendedProps.run_time[0]).format('YYYY-MM-DD HH:mm')
          ) {
            return false
          }
          return true
        } else {
          return !moment(dropLocation.startStr).isBefore(moment())
        }
      },
      moreLinkContent: (args) => {
        return '+' + args.num
      }
    }
  }

  roundPostsTime(posts: Array<EventInput>) {
    return posts.map((p) => {
      if (moment(p.start).minute() >= 30) {
        p.start = moment(p.start).minute(30).second(0).format('YYYY-MM-DD HH:mm:ss').toString()
      } else {
        p.start = moment(p.start).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss').toString()
      }

      return p
    })
  }

  setButtonsToDates() {
    const dateRows = document.getElementsByClassName('fc-list-day')
    const dateLink = document.getElementsByClassName('fc-list-day-cushion')

    Array.from(dateRows).forEach((dr: Element, index: number) => {
      const date = dr.getAttribute('data-date')
      if (!(Boolean(dateLink[index].children[2]) || !moment(date).isSameOrAfter(moment().startOf('day')))) {
        let button = document.createElement('span')
        button.innerText = this.$t('create_new_post_button').toString()
        let wrapper = document.createElement('div')
        wrapper.innerHTML = '<span class="material-icons">add_circle</span>'
        wrapper.className = 'add_wrapper'
        wrapper.onclick = () => {
          if (date) {
            this.createPost({ date, query: { date: date } })
          }
        }
        wrapper.append(button)
        dateLink[index].appendChild(wrapper)
      }
    })
  }

  setDateTimeToNewModel(date: string, timezone: string): void {
    const formatDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
    const splitFormatDate = formatDate.split(' ')

    this.newModel.date = splitFormatDate[0]
    this.newModel.time = splitFormatDate[1]

    this.newModel.timezone = timezone
  }

  handleDateClick(dateStr: string, calendar: ViewApi) {
    // if (this.routeChange) {
    this.isActive = true

    let date = ''

    if (calendar.type === 'timeGridWeek') {
      const splitDateTime = dateStr.split('T')

      date = splitDateTime[0]
      this.selectedTime = splitDateTime[1]
    } else {
      date = dateStr
    }

    this.selectedDate = date
    this.eventsByDate = this.allEvents
      .filter(e => e.extendedProps && e.extendedProps.postTime.split(' ')[0] === date)
      .map(value => {
        const calendarPost = (value.extendedProps as CalendarPosts)

        return {
          type: calendarPost.post.message.type,
          key: value.id!,
          start: moment(calendarPost.postTime).toDate(),
          text: this.getText(calendarPost.post.message),
          classNames: value!.className as Array<string>,
          targets: this.currentBoard.targets
            .reduce((acc: Array<SelectOptionData>, target: Target) => {
              if (calendarPost.targets.includes(target.id)) {
                acc.push({ image: { src: target.photo }, value: target.id, label: target.title })
              }

              return acc
            }, []),
          pin: isPostPinned(calendarPost.post.message),
          protectContent: isPostWithProtectContent(calendarPost.post.message),
          disableNotify: isPostWithDisableNotify(calendarPost.post.message),
          isRepeat: Array.isArray(calendarPost.run_time) && calendarPost.run_time.length > 1
        }
      })
    // }
  }

  createPost(postMeta?: { date: string, query?: Record<string, string> }) {
    if (postMeta) {
      const { date, query } = postMeta

      if (date >= moment().format('YYYY-MM-DD')) {
        this.gotoPost('new', query)
      } else {
        warningNotification(this.$t('planner_choose_other_date_warn').toString())
      }
    } else {
      this.gotoPost('new')
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.isActive = true

    this.selectedNode = cloneDeep(clickInfo.event)
  }

  getDataFromApi(from: string, to: string, type: string) {
    return PostsApi.getPosts('tg', {
      board: this.$store.getters.currentBoardKey,
      timezone: this.currentBoard.timezone,
      from: from,
      to: to
    })
      .then(({ data }: {data: { posts: Array<CreatedPost> }}) => {
        let events: Array<EventInput> = []
        const allTheEvents: Array<EventInput> = []

        for (let post of data.posts) {
          if (post.run_time.length === 1) {
            const calendarPost = postObjectFabric(post.run_time[0], post)

            events.push(calendarPost)
            allTheEvents.push(calendarPost)
          } else {
            const ev = this.setUniqueDates(post)
            const isFullEventsView = [ 'dayGridDay' ].includes(type)

            if (!events.length) {
              if (isFullEventsView) {
                events = ev.fullEvents
              } else {
                events = ev.shortedEvents
              }
            } else {
              if (isFullEventsView) {
                events.push(...ev.fullEvents)
              } else {
                events.push(...ev.shortedEvents)
              }
            }

            allTheEvents.push(...ev.fullEvents)
          }
        }

        this.isLoaded = true
        this.allEvents = allTheEvents
        return events
      })
      .catch(errorNotification)
  }

  setUniqueDates(post: CreatedPost) {
    let dates = post.run_time

    const grouped = groupBy(dates, (date) => date.split(' ')[0])

    const shortedDates = Object.values(grouped).reduce((acc: Array<string>, dateTime) => {
      acc.push(...dateTime.slice(0, 1))

      return acc
    }, [])

    const shortedEvents = setDatesPosts(shortedDates, post)
    const fullEvents = setDatesPosts(dates, post)

    return { shortedEvents, fullEvents }
  }

  mounted() {
    if (this.currentBoard) {
      this.isMounted = true
    } else {
      errorNotification(this.$t('board_not_found_error'))
      this.$router.replace({ name: "boards" })
    }
  }
}
