export const enum FilterTypes {
  StickerPack = "StickerPack",
  StopWord = "StopWord",
  Flood = "Flood",
  SelfLinks = "SelfLinks",
  EmailLinks = "EmailLinks",
  Sticker = "Sticker",
  Voice = "Voice",
  Images = "Images",
  Gif = "Gif",
  Audio = "Audio",
  Video = "Video",
  Files = "Files",
  Contact = "Contact",
  Warn = "Warn",
  RtlMessage = "RtlMessage",
  CapsMessage = "CapsMessage",
  BotReply = "BotReply",
  BotCommand = "BotCommand",
  ChannelReply = "ChannelReply",
  ChatReply = "ChatReply",
  AnyMessage = "AnyMessage",
  PermittedWord = "PermittedWord",
  RegularExpression = "RegularExpression",
  VideoNote = "VideoNote",
  HieroglyphMessage = "HieroglyphMessage",
  MessageLength = "MessageLength",
  Dice = "Dice",
  Referral = "Referral",
  CensorWord = "CensorWord",
  WebLinks = "WebLinks",
  ChannelMessage = "ChannelMessage",
  Spoiler = "Spoiler",
  Emoji = "Emoji",
  Story = "Story",
  Location = "Location"
}
