var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-new-users-settings"},[_c('a-card',[_c('punish-action-setting',{attrs:{"title-key":_vm.prefix + 'first_message_punish_action',"custom-builders":_vm.customPunishBuilders && _vm.customPunishBuilders.first_message_punish_action},model:{value:(_vm.settings.first_message_punish_action),callback:function ($$v) {_vm.$set(_vm.settings, "first_message_punish_action", $$v)},expression:"settings.first_message_punish_action"}}),(_vm.settings.first_message_punish_action.type === _vm.PunishActionTypesEnum.Disabled)?_c('a-alert',{attrs:{"type":"warning","message":_vm.$t('first_message_punish_action_disabled_alert_message')}}):_vm._e()],1),_c('a-card',{staticClass:"mt-2"},[_c('time-unit-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'new_limit_timeout',
          'units': [_vm.StepUnit.Second, _vm.StepUnit.Minute, _vm.StepUnit.Hour, _vm.StepUnit.Day],
          'min': 0,
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: _vm.StepUnit.Minute * 5,
            hasFieldButton: true,
            disabledValue: 0
          }
        }
      }}}),_c('number-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'new_limit_msg_count',
          'min': 0,
          'max': Number.MAX_SAFE_INTEGER,
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: 10,
            hasFieldButton: true,
            disabledValue: 0
          }
        }
      }}}),(_vm.settings.new_limit_timeout > 0 || _vm.settings.new_limit_msg_count > 0)?_c('nested-content',[_c('punish-action-setting',{attrs:{"title-key":(_vm.prefix + "new_limit_punish_action"),"message-editor-placeholders":_vm.FILTERS_PLACEHOLDERS,"custom-builders":_vm.customPunishBuilders && _vm.customPunishBuilders.new_limit_punish_action},model:{value:(_vm.settings.new_limit_punish_action),callback:function ($$v) {_vm.$set(_vm.settings, "new_limit_punish_action", $$v)},expression:"settings.new_limit_punish_action"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }