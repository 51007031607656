





































































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleConfig from '@/includes/logic/Modules/models/ModuleConfig'
import SelectCondition from '@/components/Modules/components/conditions/SelectCondition.vue'
import ConditionsConfigService from '@/includes/logic/Modules/ConditionsConfigService'
import ConfigCondition from '@/includes/logic/Modules/models/ConfigCondition'
import { ModuleConditionTypeEnum } from '@/includes/logic/Modules/types/types'
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import { TopicsConditionBuilder } from "@/includes/logic/Modules/models/conditions/TopicsCondition/TopicsConditionBuilder";

import { UseFields } from 'piramis-base-components/src/components/Pi'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import { IterableListItem } from 'piramis-base-components/src/shared/utils/IterableItemFactory'

import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  data() {
    return {
      TopicsConditionBuilder
    }
  },
  components: {
    SwitchInput,
    SelectCondition,
    Icon,
  },
})
export default class ModuleConfigSettings extends Mixins(UseFields, InputSetups, TariffsTagsHelper) {
  @VModel() config!: ModuleConfig

  @Prop() conditionsTypes!: Array<ModuleConditionTypeEnum>

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Prop({ type: Boolean, required: false, default: false }) showTransparent!: boolean

  isSelectConditionModalOpen = false

  conditionsConfigService: ConditionsConfigService | null = null

  currentCondition: IterableListItem<ConfigCondition> | null = null;

  currentParentGuid: string | null = null

  @Watch('conditionsConfigService.frontendConditions', { deep: true })
  onFrontendConditionChange() {
    if (this.conditionsConfigService) {
      this.config.conditions = this.conditionsConfigService.makeCleanConditions()
    }
  }

  handleConditionSelected(condition: ConfigCondition, parentGuid: string) {
    if (this.currentCondition) {
      const newCondition: IterableListItem<ConfigCondition> = {
        guid: this.currentCondition.guid,
        value: condition
      }

      this.editCondition(newCondition, parentGuid)
    } else {
      this.addCondition(condition, parentGuid)
    }
  }

  resetConditionsState() {
    this.currentParentGuid = null;
    this.currentCondition = null;
  }

  removeCondition(guidToRemove: string, parentGuid: string) {
    if (this.conditionsConfigService) {
      this.conditionsConfigService.removeCondition(guidToRemove, parentGuid)
    }
  }

  setConditionForEdit(condition: IterableListItem<ConfigCondition>, parentGuid: string) {
    this.currentParentGuid = parentGuid
    this.currentCondition = cloneDeep(condition)
  }

  editCondition(condition: IterableListItem<ConfigCondition>, parentGuid: string) {
    if (this.conditionsConfigService) {
      this.conditionsConfigService.editCondition(condition, parentGuid)
    }
  }

  addCondition(condition: ConfigCondition, parentGuid?: string) {
    if (this.conditionsConfigService) {
      this.conditionsConfigService.addCondition(condition, parentGuid)
    }

    this.isSelectConditionModalOpen = false
  }

  created() {
    this.conditionsConfigService = new ConditionsConfigService(this.config.conditions)
  }
}
