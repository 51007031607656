import { render, staticRenderFns } from "./PunishMessage.vue?vue&type=template&id=97a5be26&scoped=true&"
import script from "./PunishMessage.vue?vue&type=script&lang=ts&"
export * from "./PunishMessage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97a5be26",
  null
  
)

export default component.exports