import { Board } from "@/views/posts-planner/posts.types";

export type PostsPlannerState = {
  boards: Array<Board> | null
  currentBoard: Board | null
}

export const postsPlannerState = {
  boards: null,
  currentBoard: null
}
