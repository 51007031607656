import { RootState } from "@/store/state";
import { Board } from "@/views/posts-planner/posts.types";

export default {
	set_boards_to_store(state: RootState, boards: Array<Board>) {
		state.postsPlanner.boards = boards;
	},
	set_current_board(state: RootState, board: Board) {
		state.postsPlanner.currentBoard = board
	},
	clear_current_board(state: RootState) {
		state.postsPlanner.currentBoard = null
	},
};
