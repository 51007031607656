import { BasePunishActionBuilder } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import { IncrementalAction, PunishActionTypesEnum } from "../..";

export class IncrementalActionBuilder extends BasePunishActionBuilder<IncrementalAction> {
  type = PunishActionTypesEnum.Incremental

  icon = 'stacked_line_chart'

  view = () => import('@/components/Modules/punish-actions/IncrementalActionView.vue');

  getModel(): IncrementalAction {
    return new IncrementalAction()
  }
}

export const IncrementalActionBuilderInstance= new IncrementalActionBuilder()
