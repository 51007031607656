














































































































































































































































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import SelfLinksFilterHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/filters/SelfLinksFilterHandlerModule/SelfLinksFilterHandlerModuleBuilder";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import HandleUserBioField from "@/components/Modules/components/filters/fields/HandleUserBioField.vue";

import ModuleConfigSettings from '../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '../../components/ModuleConfigSettingsWrapper.vue'
import FilterJournal from "../../components/filters/FilterJournal.vue";
import FilterCommonSettings from "../../components/filters/FilterCommonSettings.vue";
import FilterNewUsersSettings from "../../components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "../../components/filters/FilterRemoveEditedSetting.vue";
import FilterExtraSettings from "../../components/filters/FilterExtraSettings.vue";
import FilterResetPunishSettings from '../../components/filters/FilterResetPunishSettings.vue'
import FilterSettingLayout from "../../components/filters/layout/FilterSettingLayout.vue";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import BlackWhiteListInput
  from 'piramis-base-components/src/components/Pi/fields/BlackWhiteListInput/BlackWhiteListInput.vue';
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue';

import { Component, Mixins, VModel } from 'vue-property-decorator'
import { BlackWhiteListItemType } from "piramis-base-components/src/components/BlackWhiteList/types";

@Component({
  data() {
    return {
      BlackWhiteListItemType
    }
  },
  components: {
    HandleUserBioField,
    FilterSettingLayout,
    FilterExtraSettings,
    FilterRemoveEditedSetting,
    FilterResetPunishSettings,
    FilterNewUsersSettings,
    FilterCommonSettings,
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SwitchInput,
    BlackWhiteListInput,
    NestedContent,
  },
})
export default class SelfLinksFilterHandlerModuleView extends Mixins(InputSetups, ModuleBindings, TariffsTagsHelper) {
  @VModel() module!: SelfLinksFilterHandlerModuleBuilder

}
