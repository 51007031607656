import { BaseChatInfo, ForumTopic } from "@/includes/types/Chat/types";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

export default class TopicsApi {
  static async addTopic(type: 'tg', chat_id: BaseChatInfo['chat_id'], title: ForumTopic["title"], value: string): AxiosPromise<{ topics: Array<ForumTopic> }> {
    return PostApi.sendRequest(type, 'addtopic', { chat_id, title, value })
  }

  static async renameTopic(type: 'tg', chat_id: BaseChatInfo['chat_id'], topic_id: ForumTopic['id'], title: ForumTopic['title']): AxiosPromise<{ topics: Array<ForumTopic> }> {
    return PostApi.sendRequest(type, 'renametopic', { chat_id, topic_id, title })
  }

  static async removeTopic(type: 'tg', chat_id: BaseChatInfo['chat_id'], topic_id: ForumTopic['id']): AxiosPromise<{ topics: Array<ForumTopic> }> {
    return PostApi.sendRequest(type, 'removetopic', { chat_id, topic_id })
  }
}
