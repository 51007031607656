





































































































































import { BaseChatInfo, ForumTopic } from "@/includes/types/Chat/types";
import TopicsApi from "@/includes/Api/Topics.api";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import { InputSetups } from "@/mixins/input-setups";

import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";
import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import EmptyData from "piramis-base-components/src/components/EmptyData/EmptyData.vue";

import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { ValidationObserver } from "vee-validate";

type NewTopicModel = { title: ForumTopic['title'], value: string }

@Component({
  components: {
    PageTitle,
    TextInput,
    ValidationObserver,
    EmptyData
  }
})
export default class TopicSettings extends Mixins(InputSetups) {
  @Prop() topics!: Array<ForumTopic>

  @Prop() chatId!: BaseChatInfo['chat_id']

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Emit()
  topicsUpdate(res: Array<ForumTopic>) {
    return res
  }

  isRenameTopicModalOpen = false

  isAddTopicModalOpen = false

  currentTopic: ForumTopic | null = null

  newTopic: NewTopicModel | null = null

  defaultNewTopicModel(): NewTopicModel {
    return {
      title: '',
      value: ''
    }
  }

  initNewTopicModel() {
    this.newTopic = { ... this.defaultNewTopicModel() }
  }

  addTopic() {
    if (this.newTopic) {
      const { title, value } = this.newTopic

      this.$baseTemplate.loader.open()

      TopicsApi.addTopic('tg', this.chatId, title, value)
        .then(res => {
          successNotification(this.$t('topics_action_added').toString())

          this.isAddTopicModalOpen = false

          this.topicsUpdate(res.data.topics)
        })
        .catch(errorNotification)
        .finally(() => {
          this.$baseTemplate.loader.close()
        })
    }
  }

  okClick() {
    if (this.currentTopic) {
      const { id, title } = this.currentTopic

      this.$baseTemplate.loader.open()

      TopicsApi.renameTopic('tg', this.chatId, id, title)
        .then(res => {
          successNotification(this.$t('topics_action_renamed').toString())

          this.isRenameTopicModalOpen = false

          this.topicsUpdate(res.data.topics)
        })
        .catch(errorNotification)
        .finally(() => {
          this.$baseTemplate.loader.close()
        })
    }
  }

  resetCurrentTopic() {
    this.currentTopic = null
  }

  renameTopic(topic: ForumTopic) {
    this.currentTopic = { ...topic }

    this.isRenameTopicModalOpen = true
  }

  removeTopic(topicId: ForumTopic['id']) {
    this.$confirm({
      title: this.$t('topics_confirm_title').toString(),
      content: this.$t('topics_confirm_content').toString(),
      okText: this.$t('remove').toString(),
      onOk: () => {
        this.$baseTemplate.loader.open()

        TopicsApi.removeTopic('tg', this.chatId, topicId)
          .then(res => {
            successNotification(this.$t('topics_action_removed').toString())

            this.topicsUpdate(res.data.topics)
          })
          .catch(errorNotification)
          .finally(() => {
            this.$baseTemplate.loader.close()
          })
      }
    })
  }

  mounted() {
    this.initNewTopicModel()

    this.$baseTemplate.saveButton.hide()
  }
}
