import chatGetters             from "./chats/getters";
import postsPlannerGetters     from "@/store/postsPlanner/getters";
import formsGetters             from './chats/questionnaire/getters'
import networksGetters             from './networks/getters'

const getters = {
  ...chatGetters,
  ...postsPlannerGetters,
  ...formsGetters,
  ...networksGetters
};

export default getters;
