var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"journal-log"},[_c('page-title'),(!_vm.$store.state.chatState.chat.log_channel_id)?_c('a-alert',{attrs:{"type":"warning"}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('journal_log_channel_not_binded_alert_message'))},slot:"message"})]):_vm._e(),_c('div',{staticClass:"filters-select flex flex-col md:flex-row md:items-end md:gap-5"},[_c('select-input',{staticClass:"w-full",attrs:{"setup":{
          'func': _vm.inputSetup,
          'args': {
            'model': this,
            'key': 'activeFilters',
            'prefix': 'journal_',
            'options': _vm.getFilters,
            'multiple': true,
          },
        }}}),_c('a-input-search',{staticClass:"mb-4 px-2 md:px-0",attrs:{"id":"search-input","allow-clear":true},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"w-full text-right mb-2"},[_c('a-button',{attrs:{"type":"primary","icon":"vertical-align-bottom","size":"small"},on:{"click":_vm.loadTable}},[_vm._v(" CSV ")])],1),_c('journal-table',{key:_vm.tableTemplate,attrs:{"logs":!_vm.searchProgress ? _vm.log : _vm.searchLog,"visible":_vm.visibleLogs}}),_c('div',{staticClass:"relative text-center mt-5"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.showButton)?_c('a-button',{staticClass:"btn-wrap",staticStyle:{"z-index":"500"},on:{"mouseenter":function($event){_vm.loading ? _vm.showArrest = true : null},"mouseleave":function($event){_vm.loading ? _vm.showArrest = false : null},"click":_vm.loadMoreButton}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_vm._v(" "+_vm._s(_vm.$t("load_more"))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading && !_vm.showArrest),expression:"loading && !showArrest"}],class:_vm.loading && !_vm.showArrest ? 'flex w-64 h-11 items-center' : ''},[_c('a-spin'),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('loaded_logs', { 0: _vm.log.length }))+" ")]),(_vm.log[_vm.log.length - 1])?_c('span',[_vm._v(" "+_vm._s(_vm.moment(_vm.log[_vm.log.length - 1].time).format('DD-MM-YYYY'))+" ")]):_vm._e()])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showArrest),expression:"showArrest"}],staticClass:"items-center justify-center w-64 h-11",class:_vm.showArrest ? 'flex' : ''},[_vm._v(" "+_vm._s(_vm.$t('stop'))+" ")])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }