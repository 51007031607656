import { BasePunishActionBuilder } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import { NotifyAction, PunishActionTypesEnum } from "../..";

import { plainToInstance } from "class-transformer";

export class NotifyActionBuilder extends BasePunishActionBuilder<NotifyAction> {
  type = PunishActionTypesEnum.Notify

  icon = 'warning_amber'

  view = () => import('@/components/Modules/punish-actions/NotifyActionView.vue');

  getModel(): NotifyAction {
    return plainToInstance(NotifyAction, {
      type: PunishActionTypesEnum.Notify,
      message: []
    })
  }
}

export const NotifyActionBuilderInstance= new NotifyActionBuilder()
