import { BaseChatInfo } from "@/includes/types/Chat/types";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import { AxiosPromise } from "axios";

export default class BindChannelApi {
  static async checkBindChannel(type:'tg', body: {chat_id: BaseChatInfo['chat_id'], channel: string}): AxiosPromise<{id: number, type: string, source_type?: string, name: string, login?: string}> {
    return PostApi.sendRequest(type, 'checkbindchannel', body)
  }
}
