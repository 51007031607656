















































































































import { getBasePunishActionBuilders } from "@/includes/logic/Modules/Factories/PunishActionsBuilderFactory";
import PunishActionPreview from "@/components/Modules/punish-actions/components/PunishActionPreview.vue";
import { createListItem } from "@/components/Modules/punish-actions/helpers";
import { BasePunishActionModel, IncrementalAction, PunishActionTypesEnum } from "@/includes/logic/Modules/models/PunishActions";
import { errorNotification } from "@/includes/NotificationService";

import AddEntityCard from "piramis-base-components/src/components/AddEntityCard.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";
import BlockMovingControls from "piramis-base-components/src/components/BlockMovingControls/BlockMovingControls.vue";
import WizardLikeListItems from "piramis-base-components/src/components/WizardLikeSelect/WizardLikeListItems.vue";
import { IWizardLikeListItem } from "piramis-base-components/src/components/WizardLikeSelect/types";
import { IterableItemFactory, IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { Component, Emit, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep } from "lodash";
import { ValidationObserver } from "vee-validate";

const ViewStateEnum = {
  List: 'List',
  Select: 'Select',
  Add: 'Add',
  Edit: 'Edit'
} as const

type FrontendPunishActions = Array<IterableListItem<BasePunishActionModel>>

@Component({
  components: {
    PunishActionPreview,
    WizardLikeListItems,
    AddEntityCard,
    ValidationObserver,
    BlockMovingControls
  }
})
export default class IncrementalActionView extends Vue {
  @VModel() model!: IncrementalAction

  @Prop() punishActionBuilders!: ReturnType<typeof getBasePunishActionBuilders>

  @Emit()
  onSaveClick() {
    return
  }

  @Emit()
  onDisableClick() {
    return null
  }

  @Watch('frontendActions', { deep: true })
  onFrontendActionsChange(value: FrontendPunishActions) {
    this.model.actions = this.getRawActions(value)
  }

  viewState: keyof typeof ViewStateEnum = "List"

  frontendActions: FrontendPunishActions = []

  currentAction: IterableListItem<BasePunishActionModel> | null = null

  itemFactory = new IterableItemFactory()

  listViewStateOption = { label: this.$t('punish_action_incremental_view_state_list').toString(), value: ViewStateEnum.List }
  selectViewStateOption = { label: this.$t('punish_action_incremental_view_state_select').toString(), value: ViewStateEnum.Select }

  simpleBreadcrumbs: Array<SelectOption> = [ this.listViewStateOption ]

  async saveAction() {
    try {
      const valid = await this.model.validate()

      if (valid) {
        this.onSaveClick()
      }
    } catch (e) {
      errorNotification(e)
    }
  }

  goto(index: number) {
    this.simpleBreadcrumbs.splice(index + 1)

    const lastEl = this.simpleBreadcrumbs.at(-1)

    if (lastEl && Object.values(ViewStateEnum).some(el => el === lastEl.value)) {
      this.viewState = lastEl.value as keyof typeof ViewStateEnum
    }
  }

  onAddEntityCardClick() {
    this.viewState = "Select"

    this.simpleBreadcrumbs.push(this.selectViewStateOption)
  }

  handleSaveClick() {
    if (this.viewState === "Add") {
      this.addCurrentAction()
    }

    if (this.viewState === "Edit") {
      this.updateAction()
    }

    this.viewState = "List"
    this.simpleBreadcrumbs = [ this.listViewStateOption ]
  }

  updateAction() {
    if (this.currentAction) {
      const idx = this.frontendActions.findIndex(a => a.guid === this.currentAction!.guid)

      if (idx !== -1) {
        this.frontendActions.splice(idx, 1, this.currentAction)
      }
    }
  }

  addCurrentAction() {
    if (this.currentAction) {
      this.frontendActions.push(this.currentAction)
    }
  }

  onEditClick(action: IterableListItem<BasePunishActionModel>) {
    this.currentAction = cloneDeep(action)
    const actionIndex = this.frontendActions.findIndex(a => a.guid === action.guid)

    this.viewState = "Edit"
    this.simpleBreadcrumbs.push({
      label: `${ this.$t(this.punishActionBuilders[this.currentAction.value.type].actionTitleKey()).toString() } #${ actionIndex + 1 } `,
      value: null
    })
  }

  onRemoveClick(guid: string) {
    this.frontendActions  = this.frontendActions.filter(a => a.guid !== guid)
  }

  selectItem(type: PunishActionTypesEnum) {
    const builder = this.punishActionBuilders[type]

    if (builder) {
      this.currentAction = this.itemFactory.create(builder.getModel())

      if (this.currentAction) {
        this.viewState = "Add"

        this.simpleBreadcrumbs.push({ label: this.$t(builder.actionTitleKey()).toString(), value: null })
      }
    }
  }

  get listData() {
    return Object.entries(this.punishActionBuilders).reduce<Array<IWizardLikeListItem>>((acc, [ key, builder ]) => {
      const blackListedActions = [ PunishActionTypesEnum.Incremental, PunishActionTypesEnum.Disabled, PunishActionTypesEnum.Log ]

      if (!blackListedActions.includes(key as PunishActionTypesEnum)) {
        acc.push(createListItem(key, builder, this.$i18n))
      }

      return acc
    }, [])
  }

  getRawActions(items: FrontendPunishActions) {
    return items.map(value => value.value)
  }

  getFrontendActions(actions: Array<BasePunishActionModel>) {
    return actions.reduce<FrontendPunishActions>((acc, value) => {
      acc.push(this.itemFactory.create(value))

      return acc
    }, [])
  }

  created() {
    this.frontendActions = this.getFrontendActions(this.model.actions ?? [])
  }
}
