import { render, staticRenderFns } from "./FilterExtraSettings.vue?vue&type=template&id=cf1b52ae&scoped=true&"
import script from "./FilterExtraSettings.vue?vue&type=script&lang=ts&"
export * from "./FilterExtraSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf1b52ae",
  null
  
)

export default component.exports