


















import {
  getConditionZoneIcon,
  getConditionZoneMiniHelpMessage,
  getConditionZoneTitle
} from "@/components/TriggerSetup/components/ConditionZone/helpers";
import { ConditionZone } from "@/components/TriggerSetup/logic/types/types";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import EntitySelect from "@/components/TriggerSetup/components/EntitySelect/EntitySelect.vue";
import { conditionsGroupsTypes, newConditionWrappers } from "@/components/TriggerSetup/conditions"
import { ConditionIcons } from "@/components/TriggerSetup/conditions/icons"

import { Entity, Item } from 'piramis-base-components/src/components/Pi/components/DefaultList/logic/types';
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types';
import { IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
import { snakeCase } from "lodash";
import { Guid } from "guid-typescript";
import Vue from "vue";

@Component({
  'components': {
    EntitySelect
  },
  'data': () => {
    return {
      conditionsGroupsTypes,
      ConditionIcons
    }
  }
})
export default class CreateCondition extends Vue {
  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop({ 'type': Boolean, required: false, default: true }) checkLicense!: boolean

  @Prop({ 'type': Object, 'required': true }) conditions!: IterableListItem<Array<IterableListItem<EntityData>>>

  @Emit()
  selectCondition(key: Item["key"]): { key: Item["key"], zone: ConditionZone | null } {
    return { key, zone: this.currentTab }
  }

  filteredConditions: Array<Entity> = []

  currentTab: ConditionZone = ConditionZone.Message;

  // this group is always seen
  get baseZoneTabs(): Array<SelectOptionData> {
    return [ {
      label: getConditionZoneTitle(ConditionZone.Common).toString(),
      value: ConditionZone.Common,
      icon: getConditionZoneIcon(ConditionZone.Common),
      miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.Common).toString(),
    } ]
  }

  // 'single (non-grouped)' zones
  singleZones = [ ConditionZone.RequestActor ]
  get singleZoneTabs(): Array<SelectOptionData> {
    return [
      {
        label: getConditionZoneTitle(ConditionZone.RequestActor),
        value: ConditionZone.RequestActor,
        icon: getConditionZoneIcon(ConditionZone.RequestActor),
        miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.RequestActor).toString(),
      },
    ]
  }

  // message zones
  messageZones = [ ConditionZone.Message, ConditionZone.ReplyMessage ]
  get messageZoneTabs(): Array<SelectOptionData> {
    return [
      {
        label: getConditionZoneTitle(ConditionZone.Message),
        value: ConditionZone.Message,
        icon: getConditionZoneIcon(ConditionZone.Message),
        miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.Message).toString(),
      },
      {
        label: getConditionZoneTitle(ConditionZone.ReplyMessage),
        value: ConditionZone.ReplyMessage,
        icon: getConditionZoneIcon(ConditionZone.ReplyMessage),
        miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.ReplyMessage).toString(),
      }
    ]
  }

  // reaction zones
  reactionZones = [ ConditionZone.ReactionMessage, ConditionZone.ReactionActor ]
  get reactionZoneTabs(): Array<SelectOptionData> {
    return [
      {
        label: getConditionZoneTitle(ConditionZone.ReactionMessage),
        value: ConditionZone.ReactionMessage,
        icon: getConditionZoneIcon(ConditionZone.ReactionMessage),
        miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.ReactionMessage).toString(),
      },
      {
        label: getConditionZoneTitle(ConditionZone.ReactionActor),
        value: ConditionZone.ReactionActor,
        icon: getConditionZoneIcon(ConditionZone.ReactionActor),
        miniHelpMessage: getConditionZoneMiniHelpMessage(ConditionZone.ReactionActor).toString(),
      }
    ]
  }

  get tabs(): Array<SelectOptionData> {
    const allZones = [ ...this.messageZoneTabs, ...this.reactionZoneTabs, ...this.baseZoneTabs, ...this.singleZoneTabs,   ]

    if (!this.conditions.value.length) {
      return allZones
    }

    const zoneGroups: Array<ConditionZone> = []
    const uniqZonesInConditionGroup = new Set(this.conditions.value.map(c => c?.value?.structure?.condition_zone))

    for (const zone of Array.from(uniqZonesInConditionGroup)) {
      const notAddedInZones = (groups: Array<ConditionZone>, zone: ConditionZone) => groups.includes(zone) && !zoneGroups.some(z => groups.includes(z))

      if (!zoneGroups.length
        || notAddedInZones(this.messageZones, zone)
        || notAddedInZones(this.reactionZones, zone)
        || notAddedInZones(this.singleZones, zone)) {
        zoneGroups.push(zone)
      }
    }

    const firstConditionZone = this.conditions.value.find(
      (c) => c.value?.structure?.condition_zone !== ConditionZone.Common
    )?.value?.structure?.condition_zone;

    if (
      !firstConditionZone
      || (zoneGroups.includes(ConditionZone.Common) && zoneGroups.length === 1)
      || (zoneGroups.filter((z) => z !== ConditionZone.Common).length > 1)
    ) {
      return allZones;
    }

    if (this.messageZones.includes(firstConditionZone)) {
      return this.baseZoneTabs.concat(this.messageZoneTabs)
    } else if (this.reactionZones.includes(firstConditionZone)) {
      return this.baseZoneTabs.concat(this.reactionZoneTabs)
    } else {
      return this.baseZoneTabs.concat(this.singleZoneTabs.filter(zt => zt.value === firstConditionZone))
    }
  }

  @Watch('conditions', { deep: true })
  onc(cnd: IterableListItem<Array<IterableListItem<EntityData>>>) {
    if (!cnd.value.length) {
      this.currentTab = this.tabs[0].value
      this.sortByTab(this.currentTab)
    }
  }

  resetTabsState() {
    this.currentTab = this.tabs[0].value
    this.sortByTab(this.currentTab)
  }

  preparedConditions(tab: string | null): Array<Entity> {
    return Object.entries(conditionsGroupsTypes).reduce((acc: Array<Entity>, [ group, item ]) => {
      const isWrapper = (type: string) => newConditionWrappers.some(w => w.key === type)

      const filtered = tab === null ? item : item.filter(i => {
        if (isWrapper(i.key)) {
          return i.extraProps?.conditions?.some((c: EntityData) => c?.extraProps?.possibleConditionZone.includes(tab))
        } else {
          return i?.extraProps?.possibleConditionZone.includes(tab)
        }
      })

      if (filtered.length || !tab) {
        acc.push({
          group: {
            guid: Guid.create().toString(),
            titleKey: `trigger_group_${ snakeCase(group) }`,
            groupItems: filtered.map(c => {
              let limited = null

              if (this.checkLicense) {
                if (typeof c.limited === 'function') {
                  limited = c.limited()
                }
              }

              return {
                key: c.key,
                title: this.$t(c.titleKey).toString(),
                helpMessage: this.$te(c.helpMessageKey) ? this.$t(c.helpMessageKey).toString() : undefined,
                limited,
                tags: typeof c.tags === 'function' ? c.tags() : c.tags,
                icon: ConditionIcons[c.key]
              }
            })
          }
        })

      }

      return acc
    }, [])
  }

  sortByTab(tab: ConditionZone) {
    this.currentTab = tab
    this.filteredConditions = this.preparedConditions(this.currentTab)
  }

  created() {
    this.resetTabsState()
  }
}
