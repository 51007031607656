



























































































































































































import ReputationMessageHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/ReputationMessageHandlerModule/ReputationMessageHandlerModuleBuilder";
import { ActorTypeEnum } from "@/includes/logic/Modules/models/modules/ReputationMessageHandlerModule/types";
import { BaseViewActionType } from "@/includes/types/Enums";
import ReputationTriggerCard from "@/components/chat/reputation/ReputationTriggerCard.vue";
import { InputSetups } from "@/mixins/input-setups";
import Placeholders from "@/mixins/placeholders/placeholders";
import ReputationTrigger
  from "@/includes/logic/Modules/models/modules/ReputationMessageHandlerModule/ReputationTrigger";
import { REPUTATION_TYPES } from "@/includes/logic/TypedChipsData";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";

import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue';
import { SelectOption } from "piramis-base-components/src/logic/types";
import { IterableItemFactory, IterableListItem } from 'piramis-base-components/src/shared/utils/IterableItemFactory';
import EmptyData from "piramis-base-components/src/components/EmptyData/EmptyData.vue";
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import MultiMessageEditorWithMediaInput
  from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import TypedChipsInput from "piramis-base-components/src/components/Pi/fields/TypedChipsInput/TypedChipsInput.vue";
import {
  MediaEditorMode
} from "piramis-base-components/src/components/NewMessageEditors/types";

import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator';
import { cloneDeep } from "lodash";
import { plainToInstance } from "class-transformer";
import MessageEditorWithMediaData from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

type ReputationTriggerType = ReputationMessageHandlerModuleBuilder['model']['params']['reputation_special_triggers'][0]

@Component({
  data() {
    return {
      MediaEditorMode,
      REPUTATION_TYPES,
    }
  },
  components: {
    NotAvailableOptionsOverlay,
    ReputationTriggerCard,
    ExtendedHelpMessage,
    EmptyData,
    NumberInput,
    SelectInput,
    MultiMessageEditorWithMediaInput,
    TypedChipsInput
  }
})
export default class ReputationTriggersSettings extends Mixins(InputSetups, Placeholders, TariffsTagsHelper, SelectOptionsMixin) {
  @VModel() module!: ReputationMessageHandlerModuleBuilder

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  frontendReputationTriggers: Array<IterableListItem<ReputationTriggerType>> = []

  @Watch('frontendReputationTriggers')
  onChange(triggers: Array<IterableListItem<ReputationTriggerType>>) {
    this.module.model.params.reputation_special_triggers = triggers.map(t => t.value)
  }

  activeTrigger: IterableListItem<ReputationTriggerType> | null = null

  modalMode: BaseViewActionType = BaseViewActionType.New

  isModalOpen = false

  iterableItemFactory = new IterableItemFactory()

  get reputationTriggerMessage() {
    return this.activeTrigger!.value!.message
  }

  set reputationTriggerMessage(value: Array<MessageEditorWithMediaData>) {
    this.activeTrigger!.value!.message = value
  }

  get actorTypeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t(`triggers_all`),
        value: ActorTypeEnum.All,
      },
      {
        label: this.$t(`triggers_admin`),
        value: ActorTypeEnum.Admin,
      },
      {
        label: this.$t(`triggers_user`),
        value: ActorTypeEnum.User,
      },
    ]
  }

  createTrigger() {
    this.activeTrigger = this.iterableItemFactory.create(plainToInstance(ReputationTrigger, {
      message: [
        {
          text                : "",
          buttons             : [],
          attachments         : [],
          remove_after        : 0,
          send_after          : 0,
          pin                 : false,
          disable_link_preview: false,
          disable_notify      : false,
        },
      ],
      triggers: [],
      min: 0,
      max: 0,
      actor_type: ActorTypeEnum.All,
      target_type: ActorTypeEnum.All,
      actor_reaction_emoji: null,
      target_reaction_emoji: null,
    }))

    this.openModal(BaseViewActionType.New)
  }

  deleteTrigger(guid: string) {
    this.frontendReputationTriggers = this.frontendReputationTriggers.filter(t => t.guid !== guid)
  }

  saveTrigger() {
    if (this.activeTrigger) {
      if (this.modalMode === BaseViewActionType.New) {
        this.addSpecialTrigger()
      }

      if (this.modalMode === BaseViewActionType.Edit) {
        this.saveSpecialTrigger()
      }

      this.isModalOpen = false
    }
  }

  addSpecialTrigger():void {
    if (this.activeTrigger) {
      this.frontendReputationTriggers.unshift(this.activeTrigger)
    }
  }

  saveSpecialTrigger():void {
    if (this.activeTrigger) {
      const idx = this.frontendReputationTriggers.findIndex(f => f.guid === this.activeTrigger!.guid)

      this.frontendReputationTriggers.splice(idx, 1, this.activeTrigger)
    }
  }

  editTrigger(item: IterableListItem<ReputationTriggerType>) {
    this.activeTrigger = cloneDeep(item)

    this.openModal(BaseViewActionType.Edit)
  }

  openModal(modalMode: BaseViewActionType):void {
    this.modalMode = modalMode
    this.isModalOpen = true
  }

  mounted() {
    this.frontendReputationTriggers = this.module.model.params.reputation_special_triggers.map(this.iterableItemFactory.create)
  }
}
