import { Board, Target } from "@/views/posts-planner/posts.types";
import { RootState } from "../state";

import tokenId from "piramis-base-components/src/shared/utils/TokenId";

export default {
	topicsStructureIfForum: (state: RootState) => (boardTokenId: string, targetId: number) => {
		const board = (state.postsPlanner.boards ?? []).find((b: Board) => b.board.token_id === Number.parseInt(boardTokenId));

		if (board) {
			const target = board.targets.find((t: Target) => t.id === targetId);

			if(target && "topics" in target) {
				return [];
			} else {
				return null;
			}
		} else {
			throw "Board not exists";
		}
	},
	topicsIfForum         : (state: RootState) => (boardTokenId: string, targetId: number) => {
		const board = (state.postsPlanner.boards ?? []).find((b: Board) => b.board.token_id === Number.parseInt(boardTokenId));

		if(board) {
			const target = board.targets.find((t: Target) => t.id === targetId);

			if(target && "topics" in target) {
				return (target.topics as Target['topics'] ?? []).map((t) => ({
					label: t.title,
					value: t.id,
				}));
			} else {
				return undefined;
			}
		} else {
			throw "Board not exists";
		}
	},
	currentBoardKey: (state: RootState) => {
		const board = state.postsPlanner.currentBoard

		if (board) {
			return tokenId.create(board.board.token_id, board.board.token_code)
		}
	},
	isCurrentBoardBrand(state: RootState, getters: any) {
		const KEEPER_BOT_CODE = '.default'

		return !getters.currentBoardKey.includes(KEEPER_BOT_CODE)
	}
};
