









































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'
import FirstCommentHandlerModuleBuilder from '@/includes/logic/Modules/models/modules/FirstCommentHandlerModule/FirstCommentHandlerModuleBuilder'
import Placeholders from "@/mixins/placeholders/placeholders";
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";

import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'
import { omit } from 'lodash'

@Component({
  data() {
    return {
      MediaEditorMode,
    }
  },
  methods: {
    omit
  },
  components: {
    MultiMessageEditorWithMediaInput,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SelectInput
  },
})
export default class FirstCommentHandlerModuleView extends Mixins(ModuleBindings, InputSetups, Placeholders, SelectOptionsMixin, TariffsTagsHelper) {
  @VModel() module!: FirstCommentHandlerModuleBuilder
}
