import { KeysOfType } from "@/includes/utils/types";
import CommonFilterSettings from "./CommonFilterSettings";
import { BasePunishActionModel } from "../PunishActions";
import { BasePunishActionBuilder } from "@/includes/logic/Modules/Builders/PunishActionBuilder";

export enum CommentsModeEnum {
  'None' = 'None',
  'Comments' = 'Comments',
  'GroupMessages' = 'GroupMessages',
  'CommentsNotMembers' = 'CommentsNotMembers',
  'CommentsMembers' = 'CommentsMembers',
}

type CommonSettingKeys = KeysOfType<CommonFilterSettings, BasePunishActionModel>

export type CustomPunishBuilders = Partial<Record<CommonSettingKeys, Array<BasePunishActionBuilder>>>
