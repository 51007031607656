


































































































import { InputSetups } from '@/mixins/input-setups'
import Placeholders from '@/mixins/placeholders/placeholders'
import ModuleConfigSettings from '../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleEnableSwitch from '@/components/Modules/components/ModuleEnableSwitch.vue'
import SendWakeupMessageModuleBuilder
  from '@/includes/logic/Modules/models/modules/SendWakeupMessageModule/SendWakeupMessageModuleBuilder'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'

import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue'
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    MultiMessageEditorWithMediaInput,
    ModuleEnableSwitch,
    TimeUnitInput,
    SwitchInput,
    NestedContent
  },
  data() {
    return {
      MediaEditorMode,
      StepUnit
    }
  },
})
export default class SendWakeupMessageModuleView extends Mixins(InputSetups, Placeholders, ModuleBindings) {
  @VModel() module!: SendWakeupMessageModuleBuilder
}
