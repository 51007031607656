































import { ModuleManagerState } from '@/includes/logic/Modules/ModulesManager'
import ModulesErrors from "@/components/CommunityDashboard/ModulesErrors.vue";
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";
import ModulesRoutesHelperMixin from "@/includes/logic/Modules/mixins/ModulesRoutesHelperMixin";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import { isChatManager } from "@/includes/logic/Modules/types/predicates";
import { ModulesTypes } from "@/includes/logic/Modules/ModulesTypes";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue';

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Guid } from "guid-typescript";
import { Route } from "vue-router";
import { NavigationGuardNext } from "vue-router/types/router";

Component.registerHooks([
  'beforeRouteLeave'
])

@Component({
  data() {
    return {
      ModuleManagerState,
      SitePermissionEnum
    }
  },
  components: {
    PermissionsBanner,
    ModulesErrors,
    PageTitle,
    CenteredColumnLayout
  },
})
export default class CurrentModule extends Mixins(ModulesRoutesHelperMixin) {

  @Watch('$route.params.CHAT_ID', { immediate: true })
  onChatChange() {
    this.initModule()
  }

  get currentModule() {
    return ModuleManagerState?.currentModule
  }

  get moduleErrors() {
    return ModuleManagerState?.errors && ModuleManagerState.errors.filter(e => e.type === this.currentModule?.model?.type)
  }

  get settingDisabled() {
    return this.isChatManagerVm && !this.$store.getters.canChangeConfig
  }

  get chatId() {
    if (this.isChatManagerVm) {
      return this.$store.getters.isChatSet && this.$store.getters.chatId
    } else {
      const network = this.$store.state.networksState.currentNetwork

      return network && network.chats.length && network.chats[0]
    }
  }

  get isChatManagerVm() {
    return isChatManager(ModuleManagerState)
  }

  moduleNotFound() {
    errorNotification(this.$t('module_notification_module_not_found').toString())

    this.gotoModulesCatalog()
  }

  initModule() {
    const moduleGuidOrType = this.$route.params.moduleGuidOrType

    if (moduleGuidOrType) {
      if (ModuleManagerState) {

        if (Guid.isGuid(moduleGuidOrType)) {
          const module = ModuleManagerState.getModuleByCriteria('guid', moduleGuidOrType.toString());

          if (module) {
            ModuleManagerState.setCurrentModule(module)
          } else {
            this.moduleNotFound()
          }
        } else if (ModulesTypes.some(t => t.name === moduleGuidOrType)) {
          this.setCurrentModule(moduleGuidOrType as ModuleTypesEnum)
        } else {
          this.moduleNotFound()
        }
      }
    } else {
      this.gotoModulesCatalog()
    }
  }

  setCurrentModule(type: ModuleTypesEnum) {
    if (ModuleManagerState) {
      const module = ModuleManagerState.getModuleByCriteria('type', type)

      if (module) {
        ModuleManagerState.setCurrentModule(module)
      } else {
        ModuleManagerState.getDefaultModule(this.chatId, type)
          .then((model) => {
            if (model) {
              ModuleManagerState!.setCurrentModule(model)
            }
          })
      }
    }
  }

  saveModule() {
    return ModuleManagerState!.saveModule(ModuleManagerState!.currentModule!)
      .then(res => {
        if (res) {
          ModuleManagerState!.updatedModuleGuid = ModuleManagerState!.currentModule?.model?.guid ?? null

          if (!ModuleManagerState!.isCurrentModuleSaved) {
            successNotification(this.$t('module_notification_module_add').toString())
          } else {
            successNotification(this.$t('module_notification_module_save').toString())
          }
        }
      })
  }

  handleSaveModule() {
    this.$baseTemplate.loader.open()

    this.saveModule()
      .then(() => {
        this.$baseTemplate.loader.close()
      })
  }

  saveModuleSettingsConfirm(onOk: () => void, onCancel: () => void) {
    this.$confirm({
      title: this.$t('confirm_save_before_leave_title').toString(),
      content: this.$t('confirm_save_before_leave_content').toString(),
      cancelText: this.$t('confirm_save_before_leave_cancel').toString(),
      okText: this.$t('save_button').toString(),
      onOk: () => {
        onOk()
      },
      onCancel: () => {
        onCancel()
      }
    })
  }

  beforeRouteLeave(to: Route, from:Route, next: NavigationGuardNext) {
    const currentModule = ModuleManagerState?.currentModule

    if (ModuleManagerState && currentModule) {
      if (ModuleManagerState.isCurrentModuleChanged && !currentModule.hasLimits() && currentModule.isSaveable() && !currentModule?.tariffTags()?.length) {
        const onOk = () => this.saveModule()
          .finally(() => {
            next()
          })

        const onCancel = () => {
          next()

          ModuleManagerState!.clearCurrentModule()
        }

        this.saveModuleSettingsConfirm(onOk, onCancel)
      } else {
        ModuleManagerState.clearCurrentModule()

        next()
      }
    } else {
      next()
    }
  }

  mounted() {
    this.$baseTemplate.saveButton.show(this.handleSaveModule)

    this.$nextTick(() => {
      if (this.settingDisabled) {
        this.$baseTemplate.saveButton.hide()
      }
    })
  }

  destroyed() {
    this.$baseTemplate.saveButton.hide()
  }
}
